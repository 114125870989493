/* Actual front-end for Drop */

import QueueUpload from "./queue-upload.js";
import DropAPI from "./drop-api.js";
import { BackBlazeB2FileStore } from "./file-store.js";
import { Base64Config } from "./config-store.js";
import Secret from "./secret.js";
import DOMLocalizer from "./dom-localizer.js";
import getSizeString from "./get-size-string.js";
import WebPushController from "./web-push-controller.js";
import PopImageController from "./pop-image-controller.js";
import ListController from "./list-controller.js";
import ChangeFileUI from "./change-file-ui.js";
import { uriFromKey, keyFromComponents, splitKey } from "./paths.js";

const webPushController = new WebPushController();
webPushController.onCheckingWebPushAvailability = async () => {
  return api.passthroughFileStore.getFile(WebPushController.VAPID_KEY_PATH);
};
webPushController.onGettingPushSubscriptionDetail =
  /**
   * @param {string} path
   */
  async (path) => api.passthroughFileStore.getFile(path, "json");

webPushController.onAddingPushSubscriptionDetail =
  /**
   * @param {string} path
   * @param {import("./web-push-controller.js").ServerPushSubscriptionJSON} serverJson
   */
  async (path, serverJson) => {
    const [result] = await api.passthroughFileStore.setFile(
      path,
      serverJson,
      "json",
    );
    return result;
  };
webPushController.onRemovingPushSubscriptionDetail =
  /**
   * @param {string} path
   */
  async (path) => {
    const [result] = await api.passthroughFileStore.deleteFile(path);
    return result;
  };

const queueUpload = new QueueUpload();

const api = new DropAPI(Base64Config, BackBlazeB2FileStore);
api.onConfigReady = async function configReady() {
  _paq.push(["trackEvent", "DropAPI", "ConfigReady"]);

  listController.handleCurrentUrlHash();
  webPushController.loggedIn();
  popImageController.loggedIn();
};
api.onConfigError =
  /**
   * @param {boolean} hasAccess
   */
  function gotConfig(hasAccess) {
    _paq.push(["trackEvent", "DropAPI", "ConfigError"]);

    alert(localizer.get("incorrectUsernamePassword"));
    secret.logout();
  };

const secret = new Secret();
secret.usernameElement = /** @type {HTMLInputElement} */ (
  document.getElementById("secret_username")
);
secret.passwordElement = /** @type {HTMLInputElement} */ (
  document.getElementById("secret_password")
);

/**
 * @param {readonly FileSystemEntry[]} entries
 */
const getFilesFromEntries = async (entries) => {
  /** @type {[File, string][]} */
  const filePrefixesMap = [];

  /**
   * @param {FileSystemEntry} entry
   * @param {string} prefix
   */
  const scanFiles = async (entry, prefix) => {
    if (entry.isDirectory) {
      const directoryReader = /** @type {FileSystemDirectoryEntry} */ (
        entry
      ).createReader();
      const entries =
        await /** @type {Promise<FileSystemEntry[]|DOMException>} */ (
          new Promise((resolve) =>
            directoryReader.readEntries(resolve, resolve),
          )
        );
      if (!Array.isArray(entries)) {
        return;
      }

      for (const subDirEntry of entries) {
        await scanFiles(subDirEntry, prefix + entry.name + "/");
      }
      return;
    }
    const file = await /** @type {Promise<File|DOMException>} */ (
      new Promise((resolve) =>
        /** @type {FileSystemFileEntry} */ (entry).file(resolve, resolve),
      )
    );
    if (!(file instanceof File)) {
      return;
    }
    filePrefixesMap.push([file, prefix]);
  };

  for (const entry of entries) {
    await scanFiles(entry, listController.listPrefix);
  }

  return filePrefixesMap;
};

/** @type {Date} */
const downloadLinkExpireDate = new Date(
  new Date().getTime() + 60 * 60 * 24 * 2 * 1000,
);

/** @type {WeakMap<File, string>} Map b/t file to upload and the target folder prefix */
const fileToListPrefixMap = new WeakMap();

// Allow dropping file to container
const fileContainerElement = /** @type {HTMLParagraphElement} */ (
  document.getElementById("file-container")
);
fileContainerElement.addEventListener("drop", async function dropFile(evt) {
  evt.preventDefault();
  document.body.classList.remove("dragover");

  if (!secret.getSecret() || !api.configStore.config) {
    alert(localizer.get("loginFirst"));

    return;
  }

  const items = evt.dataTransfer?.items;
  if (!items) {
    return;
  }

  /** @type {FileSystemEntry[]} */
  const entries = [];

  for (let i = 0; i < items.length; i++) {
    const entry = items[i].webkitGetAsEntry();
    if (!entry) {
      continue;
    }
    entries.push(entry);
  }

  const filePrefixesMap = await getFilesFromEntries(entries);

  for (const [file, prefix] of filePrefixesMap) {
    fileToListPrefixMap.set(file, prefix);
    _paq.push(["trackEvent", "QueueUpload", "AddQueue", file.name]);
  }
  await listController.addPendingFilesToList(filePrefixesMap);

  _paq.push([
    "trackEvent",
    "QueueUpload",
    "AddQueueLength",
    filePrefixesMap.length.toString(),
  ]);

  _paq.push([
    "trackEvent",
    "UI",
    "FileDrop",
    filePrefixesMap.length.toString(),
  ]);

  queueUpload.addQueue(filePrefixesMap.map(([file]) => file));
});
fileContainerElement.addEventListener("dragover", function dragoverFile(evt) {
  evt.preventDefault();
});
fileContainerElement.addEventListener("dragenter", function dragenterFile(evt) {
  document.body.classList.add("dragover");
  evt.preventDefault();
});
fileContainerElement.addEventListener("dragleave", function dragleaveFile(evt) {
  document.body.classList.remove("dragover");
  evt.preventDefault();
});

// Prevent user from leaving page when drop
// a file outside of container accidentally
window.addEventListener("drop", function dropFile(evt) {
  evt.preventDefault();
});
window.addEventListener("dragover", function dragoverFile(evt) {
  evt.preventDefault();
});

// Allow user to select files from the control
/** @type {HTMLInputElement} */ (
  document.getElementById("files")
).addEventListener("change", async function changeFiles(evt) {
  if (!secret.getSecret() || !api.configStore.config) {
    alert(localizer.get("loginFirst"));

    this.form?.reset();
    return;
  }

  if (!this.files) {
    this.form?.reset();
    return;
  }

  /** @type {[File, string][]} */
  const filePrefixesMap = Array.from(this.files).map((file) => {
    // Note: `file.webkitRelativePath` is always an empty string
    // since this is a <input multiple>, not a <input webkitdirectory>.
    // The latter however only allows user to select directories.
    // The below logic works for both.
    const prefix = splitKey(
      listController.listPrefix + (file.webkitRelativePath ?? file.name),
    ).prefix;
    return [file, prefix];
  });

  for (const [file, prefix] of filePrefixesMap) {
    fileToListPrefixMap.set(file, prefix);
    _paq.push(["trackEvent", "QueueUpload", "AddQueue", file.name]);
  }
  await listController.addPendingFilesToList(filePrefixesMap);

  _paq.push([
    "trackEvent",
    "QueueUpload",
    "AddQueueLength",
    filePrefixesMap.length.toString(),
  ]);

  _paq.push([
    "trackEvent",
    "UI",
    "FileSelect",
    filePrefixesMap.length.toString(),
  ]);

  queueUpload.addQueue(filePrefixesMap.map(([file]) => file));

  // Reset the from to clean up selected files
  // so user may be able to select again.
  this.form?.reset();
});

// Textual label status
const statusElement = /** @type {HTMLParagraphElement} */ (
  document.getElementById("status")
);

/**
 * @param {string} [name]
 * @param {number} [loaded]
 * @param {number} [total]
 * @param {boolean} [isRetry]
 */
function updateStatus(name, loaded, total, isRetry) {
  if (!isUploading) {
    document.body.classList.remove("uploading");
    statusElement.dataset.l10nKey = "done";
  } else {
    document.body.classList.add("uploading");
    if (
      name == undefined ||
      loaded === undefined ||
      total === undefined ||
      isRetry === undefined
    ) {
      throw new Error();
    }

    if (!DOMLocalizer.sharedLocalizer) {
      throw new Error();
    }
    const num = queueUpload.getQueueLength();
    statusElement.dataset.l10nKey =
      DOMLocalizer.sharedLocalizer.appendPluralRules(
        encryptingFile
          ? "preparingUploadStat"
          : isRetry
            ? "retryUploadStat"
            : "uploadStat",
        num,
      );
    statusElement.dataset.l10nSubstitutions = JSON.stringify({
      name,
      loaded: getSizeString(loaded, true),
      total: getSizeString(total, true),
      percent: total === 0 ? "0" : ((loaded / total) * 100).toFixed(2),
      num: num.toString(),
    });
  }
}
if (!queueUpload.isSupported || !window.JSON) {
  statusElement.dataset.l10nKey = "unsupported";
}

/** @type {HTMLFormElement} */ (
  document.getElementById("login-form")
).addEventListener("submit", (event) => {
  event.preventDefault();
  secret.login();
});

const changeFileUI = new ChangeFileUI({
  dialogElement: /** @type {HTMLDialogElement} */ (
    document.getElementById("change-file-dialog")
  ),
  listFiles(prefix) {
    return api.fileStore.listFiles(prefix);
  },
  getPendingFiles() {
    const files = queueUpload.getPendingFiles();
    return files.map((file) => {
      const prefix = fileToListPrefixMap.get(file);
      if (prefix === undefined) {
        throw new Error();
      }
      return [file, prefix];
    });
  },
  getCurrentFile() {
    return queueUpload.getCurrentFile();
  },
});

let showHiddenFiles = false;
let showPlainTextListing = false;

const listStatusHiddenElement = /** @type {HTMLSpanElement} */ (
  document.getElementById("list-status-hidden")
);
const listStatusLockedElement = /** @type {HTMLSpanElement} */ (
  document.getElementById("list-status-locked")
);

let lastKeypressCode = "";
/** @type {ReturnType<setTimeout>|undefined} */
let keypressTimer = undefined;

document.body.addEventListener("keypress", (event) => {
  if (
    event.target instanceof HTMLInputElement ||
    event.altKey ||
    event.ctrlKey ||
    event.metaKey ||
    event.shiftKey
  ) {
    clearTimeout(keypressTimer);
    return;
  }

  if (lastKeypressCode === "") {
    lastKeypressCode = event.code;
    event.preventDefault();
    keypressTimer = setTimeout(() => {
      lastKeypressCode = "";
    }, 500);
    return;
  }

  if (lastKeypressCode !== event.code) {
    clearTimeout(keypressTimer);
    lastKeypressCode = "";
    event.preventDefault();
    return;
  }

  clearTimeout(keypressTimer);
  lastKeypressCode = "";
  event.preventDefault();

  switch (event.code) {
    case "KeyL":
      togglePlaintextListing();
      event.preventDefault();
      break;
    case "KeyH":
      toggleHiddenFiles();
      event.preventDefault();
      break;
    case "KeyX":
      toggleExperiments();
      event.preventDefault();
      break;
  }
});

document.body.addEventListener("keypress", (event) => {
  if (event.target instanceof HTMLInputElement) {
    return;
  }

  switch (event.code) {
    case "KeyT":
      listController.openGoToForm();
      event.preventDefault();
      break;
  }
});

const togglePlaintextListing = () => {
  if (!api.encryptionAvailable) {
    return;
  }

  showPlainTextListing = !showPlainTextListing;
  _paq.push([
    "trackEvent",
    "UI",
    "TogglePlaintextListing",
    showPlainTextListing.toString(),
  ]);

  listStatusLockedElement.hidden = !showPlainTextListing;
  api.setEnabledEncryption(!showPlainTextListing);
  listController.reloadFileList();
};

const toggleHiddenFiles = () => {
  showHiddenFiles = !showHiddenFiles;
  _paq.push([
    "trackEvent",
    "UI",
    "ToggleShowHiddenFiles",
    showHiddenFiles.toString(),
  ]);

  listStatusHiddenElement.hidden = !showHiddenFiles;
  listController.reloadFileList();
};

const toggleExperiments = () => {
  _paq.push([
    "trackEvent",
    "UI",
    "ToggleExperiments",
    (!document.documentElement.classList.contains(
      "experimental-features",
    )).toString(),
  ]);

  document.documentElement.classList.toggle("experimental-features");
};

const listController = new ListController({
  listElement: /** @type {HTMLUListElement} */ (
    document.getElementById("item-list")
  ),
  listStatusElement: /** @type {HTMLSpanElement} */ (
    document.getElementById("list-status")
  ),
  breadcrumbElement: /** @type {HTMLUListElement} */ (
    document.getElementById("breadcrumb")
  ),
  /**
   * @param {string} key
   */
  async showDetailFile(key) {
    const [result] = await api.fileStore.statFile(uriFromKey(key));

    if (!result) {
      return [!!result];
    }

    const { filename, humanizedLocation } = splitKey(key);
    const {
      filename: storedFilename,
      humanizedLocation: storedHumanizedLocation,
    } = api.fileEncryption
      ? splitKey(await api.encryptName(key))
      : { filename, humanizedLocation };
    const { contentType, size, storedSize, uploaded, eTag, modified, created } =
      result;

    alert(
      localizer.get("fileDetailMessage", {
        filename,
        storedFilename,
        path: humanizedLocation,
        storedPath: storedHumanizedLocation,
        contentType: contentType ?? "-",
        size: size !== undefined ? getSizeString(size) : "-",
        storedSize: storedSize !== undefined ? getSizeString(storedSize) : "-",
        uploaded: uploaded
          ? new Intl.DateTimeFormat(localizer.locale, {
              dateStyle: "short",
              timeStyle: "short",
            }).format(uploaded)
          : "-",
        eTag: eTag ?? "-",
        modified: modified
          ? new Intl.DateTimeFormat(localizer.locale, {
              dateStyle: "short",
              timeStyle: "short",
            }).format(modified)
          : "-",
        created: created
          ? new Intl.DateTimeFormat(localizer.locale, {
              dateStyle: "short",
              timeStyle: "short",
            }).format(created)
          : "-",
        encryptedState: api.fileEncryption
          ? localizer.get("encryptedStateYes")
          : localizer.get("encryptedStateNo"),
      }),
    );
    return [!!result];
  },
  /**
   * @param {string} key
   */
  async deleteFile(key) {
    const [result, errorInfo] = await api.fileStore.deleteFile(uriFromKey(key));
    if (result) {
      _paq.push(["trackEvent", "DropAPI", "DeleteFileSuccess", key]);
    } else {
      const name = splitKey(key).filename;
      if (errorInfo) {
        alert(
          localizer.get("fileDeleteError", { name }) +
            "\n" +
            localizer.get("errorWithInfo", {
              code: errorInfo.code,
              message: errorInfo.message,
            }),
        );
      } else {
        alert(localizer.get("fileDeleteError", { name }));
      }
      _paq.push(["trackEvent", "DropAPI", "DeleteFileError", key]);
    }
    return [result];
  },
  async getFile(key, delegate) {
    if (!api.fileEncryption) {
      const result = await api.fileStore.getFile(
        uriFromKey(key),
        "file",
        delegate,
      );
      if (result) {
        _paq.push(["trackEvent", "DropAPI", "GetFileSuccess", key]);
      } else {
        const name = splitKey(key).filename;
        alert(localizer.get("fileGetError", { name }));
        _paq.push(["trackEvent", "DropAPI", "GetFileError", key]);
        return [null];
      }

      return [result];
    }

    const result = await api.fileStore.getFile(
      uriFromKey(key),
      "stream",
      delegate,
    );
    if (result) {
      _paq.push(["trackEvent", "DropAPI", "GetFileSuccess", key]);
    } else {
      const name = splitKey(key).filename;
      alert(localizer.get("fileGetError", { name }));
      _paq.push(["trackEvent", "DropAPI", "GetFileError", key]);
      return [null];
    }

    const { type, lastModified, stream: sourceStream } = result;

    const stream = await api.decryptFile(sourceStream);
    if (!stream) {
      return [null];
    }
    const reader = stream.getReader();
    /** @type {Uint8Array<ArrayBuffer>[]} */
    const blobParts = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      const array = new Uint8Array(value.byteLength);
      array.set(value);
      blobParts.push(array);
    }

    return [
      new File(blobParts, splitKey(key).filename, {
        type,
        lastModified,
      }),
    ];
  },
  async changeFile(key) {
    /** @type {string|undefined} */
    let newKey = undefined;
    while (true) {
      newKey = await changeFileUI.open(key, newKey);

      if (!newKey) {
        changeFileUI.close();
        return [false];
      }

      const [result, errorInfo] = await api.fileStore.moveFile(
        uriFromKey(key),
        uriFromKey(newKey),
      );

      if (!result) {
        const name = splitKey(key).filename;
        if (errorInfo) {
          alert(
            localizer.get("fileChangeError", { name }) +
              "\n" +
              localizer.get("errorWithInfo", {
                code: errorInfo.code,
                message: errorInfo.message,
              }),
          );
        } else {
          alert(localizer.get("fileChangeError", { name }));
        }
        _paq.push(["trackEvent", "DropAPI", "ChangeFileError", key]);

        continue;
      }

      const newPrefix = splitKey(newKey).prefix;
      _paq.push(["trackEvent", "DropAPI", "ChangeFileSuccess", key]);
      changeFileUI.close();

      if (newPrefix.includes(listController.listPrefix)) {
        listController.reloadFileList();
      }
      return [result];
    }
  },
  /**
   * @param {string} prefix
   */
  async listFiles(prefix) {
    const [results, errorInfo] = await api.fileStore.listFiles(prefix);
    if (!results && errorInfo?.code === "RequestTimeTooSkewed") {
      // Try again since the awsTimeOffset should have been updated now.
      return this.listFiles(prefix);
    }

    _paq.push(["trackEvent", "DropAPI", "ListFiles", prefix]);
    return [results, errorInfo];
  },
  /**
   * @param {string} key
   * @param {File} file
   */
  async cancelFile(key, file) {
    queueUpload.cancelFile(file);
    _paq.push(["trackEvent", "UI", "CancelFile", key]);
  },
  async getStoredSize(size) {
    return api.fileEncryption ? await api.encryptedFileSize(size) : size;
  },
  /**
   * @param {string} hash
   */
  pushState(hash) {
    window.history.pushState(null, "", hash);
  },
  getPendingUploadFiles() {
    const files = queueUpload.getPendingFiles();
    return files.map((file) => {
      const prefix = fileToListPrefixMap.get(file);
      if (prefix === undefined) {
        throw new Error();
      }
      return [file, prefix];
    });
  },
  getCurrentUploadingFile() {
    return queueUpload.getCurrentFile();
  },
  async getDownloadUrl(key) {
    if (api.fileEncryption) {
      return "";
    }

    return api.fileStore.getDownloadUrl(
      uriFromKey(key),
      downloadLinkExpireDate,
    );
  },
  toggleHiddenFiles,
  toggleExperiments,
  togglePlaintextListing,
  get showHiddenFiles() {
    return showHiddenFiles;
  },
});

window.addEventListener("popstate", () =>
  listController.handleCurrentUrlHash(),
);

const popImageController = new PopImageController({
  container: document.body,
  async getImages() {
    const [results] = await api.fileStore.listFiles(
      PopImageController.IMAGES_PREFIX,
    );
    if (!results) {
      return [];
    }

    return (
      await Promise.all(
        results.map(async (result) => {
          return await api.fileStore.getFile(uriFromKey(result.key), "file");
        }),
      )
    ).filter((file) => file instanceof File);
  },
});

/**
 * The uploading status to be reflected on UI.
 * This is different from `QueueUpload##isUploading`
 * because we may deviate when API calls failed.
 */
let isUploading = false;

/** @type {File|null} */
let encryptingFile = null;

/**
 * @param {File} file
 */
queueUpload.onFileStart = async function (file) {
  isUploading = true;
  updateStatus(file.name, 0, file.size, false);

  const prefix = fileToListPrefixMap.get(file);
  if (prefix === undefined) {
    throw new Error("");
  }

  if (prefix === listController.listPrefix) {
    listController.addFileToList(
      {
        is: "file",
        key: listController.listPrefix + file.name,
        filename: file.name,
        uploaded: null,
        size: file.size,
        storedSize: api.fileEncryption
          ? await api.encryptedFileSize(file.size)
          : file.size,
        downloadUrl: "",
      },
      "uploading",
      file,
    );
  }

  if (api.fileEncryption) {
    encryptingFile = file;
    updateStatus(file.name, 0, file.size, false);
  }
  const uploadFile = api.fileEncryption ? await api.encryptFile(file) : file;
  if (api.fileEncryption) {
    if (encryptingFile !== file) {
      return;
    }
    encryptingFile = null;
  }

  if (uploadFile === null) {
    isUploading = false;
    updateStatus();
    listController.removePendingFileFromList(file);
    alert(localizer.get("fileUploadEncryptionError", { name: file.name }));
    return false;
  }
  updateStatus(file.name, 0, file.size, false);

  if (uploadFile instanceof File) {
    queueUpload.uploadFileMap.set(file, uploadFile);
  } else {
    queueUpload.uploadFileMap.set(file, {
      size: await api.encryptedFileSize(file.size),
      toStream() {
        return uploadFile;
      },
      async toBlob() {
        const reader = uploadFile.getReader();
        /** @type {Uint8Array<ArrayBuffer>[]} */
        const blobParts = [];
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          const array = new Uint8Array(value.byteLength);
          array.set(value);
          blobParts.push(array);
        }

        return new Blob(blobParts);
      },
    });
  }

  const uri = uriFromKey(keyFromComponents({ prefix, filename: file.name }));
  if (queueUpload.isMultipart(file)) {
    _paq.push(["trackEvent", "QueueUploadMultipart", "Start", file.name]);
    const [status, errorInfo] = await api.fileStore.createMultipartUpload(
      uri,
      file,
    );
    if (!status) {
      isUploading = false;
      updateStatus();

      _paq.push([
        "trackEvent",
        "QueueUploadMultipart",
        "StartError",
        file.name,
      ]);

      if (errorInfo) {
        alert(
          localizer.get("fileUploadStartError", { name: file.name }) +
            "\n" +
            localizer.get("errorWithInfo", {
              code: errorInfo.code,
              message: errorInfo.message,
            }),
        );
      } else {
        alert(localizer.get("fileUploadStartError", { name: file.name }));
      }
      listController.removePendingFileFromList(file);
      return false;
    }
  }
};

/**
 * @param {File} file
 */
queueUpload.onUploadStart = async function (file) {
  const prefix = fileToListPrefixMap.get(file);
  if (prefix === undefined) {
    throw new Error("");
  }

  const uri = uriFromKey(keyFromComponents({ prefix, filename: file.name }));
  const uploadInfo = await api.fileStore.getUploadAuthorizationInfo(uri, file);

  queueUpload.method = uploadInfo.method;
  queueUpload.headers = uploadInfo.headers;
  queueUpload.headers["Content-Type"] = file.type;
  queueUpload.url = uploadInfo.url;
};

/**
 * @param {File} file
 * @param {number} index
 */
queueUpload.onUploadPartStart = async function (file, index) {
  const prefix = fileToListPrefixMap.get(file);
  if (prefix === undefined) {
    throw new Error("");
  }

  const uri = uriFromKey(keyFromComponents({ prefix, filename: file.name }));
  const uploadInfo = await api.fileStore.getUploadPartAuthorizationInfo(
    uri,
    file,
    index + 1,
  );

  queueUpload.method = uploadInfo.method;
  queueUpload.headers = uploadInfo.headers;
  queueUpload.url = uploadInfo.url;
};

// When there is a progress we will update the progress
queueUpload.onUploadProgress =
  /**
   * @param {File} file
   * @param {XMLHttpRequest} xhr
   * @param {number} loaded
   * @param {number} total
   * @param {number} retries
   */
  function (file, xhr, loaded, total, retries) {
    updateStatus(file.name, loaded, total, retries !== 0);
  };

queueUpload.onUploadPartRetry =
  /**
   * @param {File} file
   * @param {XMLHttpRequest} xhr
   * @param {number} loaded
   * @param {number} total
   * @param {number} retries
   */
  function (file, xhr, loaded, total, retries) {
    _paq.push([
      "trackEvent",
      "QueueUploadMultipart",
      "UploadRetry",
      `${file.name} (${retries.toString()})`,
      QueueUpload.partSizeMultiple,
    ]);
  };

// When upload is completed we'll process the result from server.
queueUpload.onUploadComplete =
  /**
   * @param {File} file
   * @param {XMLHttpRequest} xhr
   */
  async function (file, xhr) {
    const apiInfo = api.fileStore.handleUploadComplete(xhr);
    if (!apiInfo.success) {
      _paq.push(["trackEvent", "QueueUpload", "UploadError", file.name]);

      isUploading = false;
      updateStatus();

      const errorInfo = apiInfo.errorInfo;
      if (errorInfo) {
        _paq.push([
          "trackEvent",
          "QueueUpload",
          "UploadErrorMessage",
          errorInfo.message,
        ]);

        alert(
          localizer.get("fileUploadError", { name: file.name }) +
            "\n" +
            localizer.get("errorWithInfo", {
              code: errorInfo.code,
              message: errorInfo.message,
            }),
        );
      } else {
        _paq.push([
          "trackEvent",
          "QueueUpload",
          "UploadErrorMessage",
          "Unknown",
        ]);

        alert(localizer.get("fileUploadError", { name: file.name }));
      }

      listController.removePendingFileFromList(file);
      return false;
    }

    _paq.push(["trackEvent", "QueueUpload", "UploadSuccess", file.name]);
  };

queueUpload.onUploadPartComplete =
  /**
   * @param {File} file
   * @param {XMLHttpRequest} xhr
   */
  async function (file, xhr) {
    const apiInfo = api.fileStore.handleUploadPartComplete(xhr);

    if (apiInfo.success) {
      _paq.push([
        "trackEvent",
        "QueueUploadMultipart",
        "UploadSuccess",
        file.name,
        QueueUpload.partSizeMultiple,
      ]);
      return apiInfo.data;
    }

    isUploading = false;
    updateStatus();

    const errorInfo = apiInfo.errorInfo;
    if (errorInfo) {
      _paq.push([
        "trackEvent",
        "QueueUploadMultipart",
        "UploadErrorMessage",
        errorInfo.message,
        QueueUpload.partSizeMultiple,
      ]);

      alert(
        localizer.get("fileUploadError", { name: file.name }) +
          "\n" +
          localizer.get("errorWithInfo", {
            code: errorInfo.code,
            message: errorInfo.message,
          }),
      );
    } else {
      _paq.push([
        "trackEvent",
        "QueueUploadMultipart",
        "UploadErrorMessage",
        "Unknown",
        QueueUpload.partSizeMultiple,
      ]);

      alert(localizer.get("fileUploadError", { name: file.name }));
    }

    listController.removePendingFileFromList(file);
    return false;
  };

/**
 * @param {File} file
 * @param {number} totalPartCount
 * @param {any[]} [partsData]
 */
queueUpload.onFileComplete = async function (file, totalPartCount, partsData) {
  const prefix = fileToListPrefixMap.get(file);
  if (prefix === undefined) {
    throw new Error("");
  }

  listController.setPendingFileCancelUploadDisabled(file, true);

  if (queueUpload.isMultipart(file)) {
    if (!partsData) {
      throw new Error("");
    }

    const uri = uriFromKey(keyFromComponents({ prefix, filename: file.name }));
    const [status, errorInfo] = await api.fileStore.completeMultipartUpload(
      uri,
      file,
      totalPartCount,
      partsData,
    );
    if (!status) {
      if (errorInfo) {
        _paq.push([
          "trackEvent",
          "QueueUploadMultipart",
          "UploadErrorCompleteMessage",
          errorInfo.message,
        ]);

        alert(
          localizer.get("fileUploadCompleteError", { name: file.name }) +
            "\n" +
            localizer.get("errorWithInfo", {
              code: errorInfo.code,
              message: errorInfo.message,
            }),
        );
      } else {
        _paq.push([
          "trackEvent",
          "QueueUploadMultipart",
          "UploadErrorCompleteMessage",
          "Unknown",
        ]);

        alert(localizer.get("fileUploadCompleteError", { name: file.name }));
      }

      isUploading = false;
      listController.removePendingFileFromList(file);
      updateStatus();

      return;
    }
  }

  isUploading = false;
  updateStatus();

  const key = keyFromComponents({ prefix, filename: file.name });

  const downloadUrl = api.fileEncryption
    ? ""
    : await api.fileStore.getDownloadUrl(
        uriFromKey(key),
        downloadLinkExpireDate,
      );

  listController.removePendingFileFromList(file);
  listController.revokeDownloadUrl(key);
  if (prefix === listController.listPrefix) {
    listController.addFileToList({
      is: "file",
      key,
      filename: file.name,
      uploaded: new Date(),
      size: file.size,
      storedSize: api.fileEncryption
        ? await api.encryptedFileSize(file.size)
        : file.size,
      downloadUrl,
    });
  }
};

/**
 * @param {File} file
 */
queueUpload.onCurrentUploadCancelled = async function (file) {
  if (encryptingFile === file) {
    encryptingFile = null;

    isUploading = false;
    updateStatus();
    return;
  }

  if (queueUpload.isMultipart(file)) {
    const prefix = fileToListPrefixMap.get(file);
    if (prefix === undefined) {
      throw new Error();
    }
    const uri = uriFromKey(keyFromComponents({ prefix, filename: file.name }));
    await api.fileStore.abortMultipartUpload(uri, file);
  }

  isUploading = false;
  updateStatus();
};

/**
 * @param {string} name
 */
queueUpload.onMaxFileSizeExceed = (name) => {
  _paq.push(["trackEvent", "QueueUpload", "MaxFileSizeExceed", name]);

  alert(
    localizer.get("fileSizeExceedError", {
      name,
    }),
  );
};

/**
 *  @param {{ username: string; password: string; }} secret
 */
secret.onLogin = function (secret) {
  _paq.push(["trackEvent", "Secret", "Login", secret.username]);

  api.configStore.SECRET = secret;
  api.getConfig();

  document.body.classList.remove("auth-needed");
  statusElement.dataset.l10nKey = "statusInstruction";
};
secret.onLogout = function loggedOut() {
  _paq.push([
    "trackEvent",
    "Secret",
    "Logout",
    api.configStore.SECRET?.username,
  ]);

  api.configStore.SECRET = null;
  api.clearConfig();

  document.body.classList.add("auth-needed");
  delete statusElement.dataset.l10nKey;
  statusElement.textContent = "";

  webPushController.loggedOut();
  listController.loggedOut();
};

// Start the transition
document.body.classList.remove("uninit");

// There is no pseudo-class like :from() to target for
// the animation when leaving 'uninit' state.
// We will have to introduce a new state here.
document.body.classList.add("leave-uninit");
// We should be using animationend & webkitAnimationEnd here, however
// the event will never be triggered if the animation is interrupted.
setTimeout(function animationend() {
  document.body.classList.remove("leave-uninit");
  secret.usernameElement?.focus();
}, 1010);

document.body.classList.add("auth-needed");

const localizer = new DOMLocalizer();
localizer.observe(document.documentElement);
DOMLocalizer.sharedLocalizer = localizer;
