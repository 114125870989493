import Localizer from "./localizer.js";
import getSizeString from "./get-size-string.js";

/**
 * @template {any} T
 * @param {T} obj
 */
const NonNullable = (obj) => /** @type {NonNullable<T>} */ (obj);

/**
 * @typedef {CustomEvent<{key: string, folderName: string}>} BrowseCustomEvent
 */

export class DropFileHandle {
  /**
   * @param {{element: HTMLLIElement, onFileRemove: (handle: DropFileHandle) => void}} delegate
   * @param {"idle"|"uploading"|"pendingUpload"|"pendingDownload"|"downloaded"} [status]
   */
  constructor(delegate, status = "idle") {
    this.#element = delegate.element;
    this.#delegate = delegate;
    this.#status = status;
  }

  #element;
  #delegate;
  /** @type {"idle"|"uploading"|"pendingUpload"|"pendingDownload"|"downloaded"|"pendingDeletion"} */
  #status;

  /**
   * @param {boolean} pending
   */
  setPendingDeletion(pending) {
    if (
      (pending && this.#status !== "idle" && this.#status !== "downloaded") ||
      (!pending && this.#status !== "pendingDeletion")
    ) {
      throw new Error();
    }
    this.#status = pending ? "pendingDeletion" : "idle";
    this.#element.classList.toggle("pending-deletion", pending);
  }

  /**
   * @param {boolean} pending
   * @param {number} [loaded]
   * @param {number} [total]
   */
  setPendingDownload(pending, loaded, total) {
    if (
      (pending &&
        this.#status !== "idle" &&
        this.#status !== "pendingDownload") ||
      (!pending && this.#status !== "pendingDownload")
    ) {
      throw new Error();
    }

    this.#element.classList.toggle("pending-download", pending);
    const existingLabelElement = this.#element.querySelector(".status-label");
    existingLabelElement?.remove();
    if (pending) {
      this.#status = "pendingDownload";
      const referenceElement = this.#element.querySelector("a + ul");
      const labelElement = document.createElement("span");
      labelElement.classList.add("status-label");
      if (loaded === undefined || total === undefined) {
        labelElement.dataset.l10nKey = "fileDownloadingTitleLabelAfter";
        delete labelElement.dataset.l10nSubstitutions;
      } else {
        labelElement.dataset.l10nKey =
          "fileDownloadingTitleLabelWithProgressAfter";
        labelElement.dataset.l10nSubstitutions = JSON.stringify({
          loaded: getSizeString(loaded),
          total: getSizeString(total),
          percent: total === 0 ? "0" : ((loaded / total) * 100).toPrecision(3),
        });
      }
      this.#element.insertBefore(labelElement, referenceElement);
    } else {
      this.#status = "idle";
    }
  }

  /**
   * @param {string} url
   */
  setDownloadBlobUrl(url) {
    if (this.#status !== "idle") {
      return;
    }
    this.#status = "downloaded";
    this.#element.classList.add("downloaded");
    const anchorElement = this.#element.querySelector("a");
    NonNullable(anchorElement).href = url;

    const referenceElement = this.#element.querySelector("a + ul");
    const labelElement = document.createElement("span");
    labelElement.classList.add("status-label");
    labelElement.dataset.l10nKey = "fileDownloadedTitleLabelAfter";
    this.#element.insertBefore(labelElement, referenceElement);
  }

  unsetDownloadBlobUrl() {
    if (this.#status !== "downloaded") {
      return;
    }

    this.#status = "idle";
    this.#element.classList.remove("downloaded");
    const anchorElement = this.#element.querySelector("a");
    NonNullable(anchorElement).removeAttribute("href");

    const labelElement = this.#element.querySelector(".status-label");
    labelElement?.remove();
  }

  /**
   *
   * @param {boolean} disabled
   */
  setCancelUploadDisabled(disabled) {
    const buttonElement = /** @type {HTMLButtonElement|null} */ (
      this.#element.querySelector(".cancel-upload-item")
    );
    if (!buttonElement) {
      return;
    }
    buttonElement.disabled = disabled;
  }

  remove() {
    this.#element.remove();
    this.#delegate.onFileRemove(this);
  }
}

export default class DropItemList {
  /**
   * @param {HTMLUListElement} element
   * @param {HTMLSpanElement} [statusElement]
   */
  constructor(element, statusElement) {
    this.#element = element;
    this.#element.addEventListener("click", this.#handleClickEventListener);
    this.#element.addEventListener(
      "contextmenu",
      this.#handleContextMenuEventListener,
    );
    this.#statusElement = statusElement;
  }

  isSummary = false;

  #element;
  #statusElement;

  /** @type {WeakMap<HTMLLIElement, DropFileHandle>} */
  #elementToFileHandleMap = new WeakMap();

  #count = 0;
  #totalSize = 0;

  #handleClickEventListener =
    /**
     * @param {MouseEvent} event
     */
    (event) => this.#handleClickEvent(event);
  #handleContextMenuEventListener =
    /**
     * @param {MouseEvent} event
     */

    (event) => this.#handleContextMenuEvent(event);

  destroy() {
    this.#element.removeEventListener("click", this.#handleClickEventListener);
    this.#element.removeEventListener(
      "contextmenu",
      this.#handleContextMenuEventListener,
    );
  }

  /** @type {((deletionInfo: {key: string, filename: string, handle: DropFileHandle }) => void)|null} */
  onDeleteFile = null;

  /** @type {((changeInfo: {key: string, filename: string, handle: DropFileHandle }) => void)|null} */
  onChangeFile = null;

  /** @type {((showDetailInfo: {key: string, filename: string, handle: DropFileHandle }) => void)|null} */
  onShowDetailFile = null;

  /** @type {((cancelUploadInfo: {key: string, filename: string, handle: DropFileHandle }) => void)|null} */
  onCancelUploadFile = null;

  /** @type {((browseFolderInfo: {key: string, folderName: string}) => void)|null} */
  onBrowseFolder = null;

  /** @type {((info: {key: string, filename: string, handle: DropFileHandle }) => void)|null} */
  onFileDidAddToList = null;

  /** @type {((info: {key: string, filename: string, handle: DropFileHandle }) => void)|null} */
  onFileDidRemoveFromList = null;

  /** @type {((cancelUploadInfo: {key: string, filename: string, handle: DropFileHandle }) => void)|null} */
  onStartDownloadFile = null;

  /**
   * @param {MouseEvent} event
   */
  #handleClickEvent(event) {
    const element = /** @type {HTMLElement} */ (event.target);

    switch (element.dataset.action) {
      case "browse": {
        const key = element.dataset.key;
        const folderName = element.dataset.folderName;
        if (key === undefined || folderName === undefined) {
          throw new Error();
        }

        event.preventDefault();

        _paq.push(["trackEvent", "UI", "BrowseFolder", key]);

        this.onBrowseFolder?.({
          key,
          folderName,
        });

        break;
      }

      case "start-download": {
        const listElement = /** @type {HTMLLIElement} */ (
          element.parentNode?.parentNode
        );
        const { key, filename, status } =
          this.#elementToFileInfoMap.get(listElement) ?? {};
        if (
          key === undefined ||
          filename === undefined ||
          status !== undefined
        ) {
          throw new Error();
        }

        if (/** @type {HTMLButtonElement} */ (element).disabled) {
          return;
        }

        _paq.push(["trackEvent", "UI", "StartDownload", key]);

        const handle = this.#elementToFileHandleMap.get(listElement);
        if (!handle) {
          throw new Error();
        }

        this.onStartDownloadFile?.({
          key,
          filename,
          handle,
        });

        break;
      }

      case "download": {
        if (!element.hasAttribute("href")) {
          break;
        }

        const listElement = /** @type {HTMLLIElement} */ (element.parentNode);
        const { filename } = this.#elementToFileInfoMap.get(listElement) ?? {};
        if (filename === undefined) {
          throw new Error();
        }
        _paq.push(["trackEvent", "UI", "DownloadLinkClick", filename]);
        break;
      }

      case "save": {
        const listElement = /** @type {HTMLLIElement} */ (
          element.parentNode?.parentNode
        );
        const { key, filename, status } =
          this.#elementToFileInfoMap.get(listElement) ?? {};
        if (key === undefined || filename === undefined) {
          throw new Error();
        }

        const anchorElement = listElement.querySelector("a");
        const url = NonNullable(anchorElement).href;

        const downloadAnchorElement = document.createElement("a");
        downloadAnchorElement.download = filename;
        downloadAnchorElement.href = url;
        downloadAnchorElement.click();
        break;
      }

      case "cancel-upload": {
        const listElement = /** @type {HTMLLIElement} */ (
          element.parentNode?.parentNode
        );
        const { key, filename, status } =
          this.#elementToFileInfoMap.get(listElement) ?? {};
        if (
          !Localizer.sharedLocalizer ||
          key === undefined ||
          filename === undefined ||
          status === undefined
        ) {
          throw new Error();
        }

        if (/** @type {HTMLButtonElement} */ (element).disabled) {
          return;
        }

        if (
          status === "uploading" &&
          !window.confirm(
            Localizer.sharedLocalizer.get("cancelUploadConfirmation", {
              filename,
            }),
          )
        ) {
          return;
        }

        _paq.push(["trackEvent", "UI", "CancelUpload", key]);

        const handle = this.#elementToFileHandleMap.get(listElement);
        if (!handle) {
          throw new Error();
        }

        this.onCancelUploadFile?.({
          key,
          filename,
          handle,
        });

        break;
      }

      case "show-detail": {
        const listElement = /** @type {HTMLLIElement} */ (
          element.parentNode?.parentNode
        );
        const { key, filename } =
          this.#elementToFileInfoMap.get(listElement) ?? {};
        if (
          !Localizer.sharedLocalizer ||
          key === undefined ||
          filename === undefined
        ) {
          throw new Error();
        }

        _paq.push(["trackEvent", "UI", "ShowDetailFile", key]);

        const handle = this.#elementToFileHandleMap.get(listElement);
        if (!handle) {
          throw new Error();
        }

        this.onShowDetailFile?.({
          key,
          filename,
          handle,
        });

        break;
      }

      case "change": {
        const listElement = /** @type {HTMLLIElement} */ (
          element.parentNode?.parentNode
        );
        const { key, filename } =
          this.#elementToFileInfoMap.get(listElement) ?? {};
        if (
          !Localizer.sharedLocalizer ||
          key === undefined ||
          filename === undefined
        ) {
          throw new Error();
        }

        _paq.push(["trackEvent", "UI", "MoveFile", key]);

        const handle = this.#elementToFileHandleMap.get(listElement);
        if (!handle) {
          throw new Error();
        }

        this.onChangeFile?.({
          key,
          filename,
          handle,
        });

        break;
      }

      case "delete": {
        const listElement = /** @type {HTMLLIElement} */ (
          element.parentNode?.parentNode
        );
        const { key, filename } =
          this.#elementToFileInfoMap.get(listElement) ?? {};
        if (
          !Localizer.sharedLocalizer ||
          key === undefined ||
          filename === undefined
        ) {
          throw new Error();
        }
        if (
          !window.confirm(
            Localizer.sharedLocalizer.get("deletionConfirmation", {
              name: filename,
            }),
          )
        ) {
          return;
        }

        _paq.push(["trackEvent", "UI", "DeleteFile", key]);

        const handle = this.#elementToFileHandleMap.get(listElement);
        if (!handle) {
          throw new Error();
        }

        this.onDeleteFile?.({
          key,
          filename,
          handle,
        });

        break;
      }
    }
  }

  /**
   * @param {MouseEvent} event
   */
  #handleContextMenuEvent(event) {
    const element = /** @type {HTMLElement} */ (event.target);

    switch (element.dataset.action) {
      case "download": {
        const listElement = /** @type {HTMLLIElement} */ (element.parentNode);
        const { filename } = this.#elementToFileInfoMap.get(listElement) ?? {};
        if (filename === undefined) {
          throw new Error();
        }
        _paq.push(["trackEvent", "UI", "DownloadLinkContextMenu", filename]);
        break;
      }
    }
  }

  resetList() {
    const elements = /** @type {HTMLLIElement[]} */ (
      Array.from(this.#element.querySelectorAll("li.file.item"))
    );

    this.#element.textContent = "";

    elements.forEach((element) => {
      const { key, filename } = this.#elementToFileInfoMap.get(element) ?? {};
      const handle = this.#elementToFileHandleMap.get(element);
      if (!key || !filename || !handle) {
        throw new Error();
      }
      this.onFileDidRemoveFromList?.({ key, filename, handle });
    });

    this.#count = 0;
    this.#totalSize = 0;
    this.#elementToFileHandleMap = new WeakMap();
    this.#elementToFileInfoMap = new WeakMap();
  }

  /**
   * @param {boolean} isReload
   */
  setListLoading(isReload) {
    if (!isReload) {
      this.resetList();
    }

    if (!this.#statusElement) {
      return;
    }
    this.#statusElement.dataset.l10nKey = "listStatusLoading";
    delete this.#statusElement.dataset.l10nSubstitutions;
  }

  setListLoadingError() {
    this.resetList();

    if (!this.#statusElement) {
      return;
    }
    this.#statusElement.dataset.l10nKey = "listStatusError";
    delete this.#statusElement.dataset.l10nSubstitutions;
  }

  /** @type {WeakMap<HTMLLIElement,{ key: string, filename: string, downloadUrl: string, size: number|null, uploaded: Date|null, status?: "uploading"|"pendingUpload" }>} */
  #elementToFileInfoMap = new WeakMap();

  /**
   * @param {Object} fileInfo
   * @param {string} fileInfo.key
   * @param {string} fileInfo.filename
   * @param {string} fileInfo.downloadUrl
   * @param {number|null} fileInfo.size
   * @param {number|null} fileInfo.storedSize
   * @param {Date|null} fileInfo.uploaded
   * @param {"uploading"|"pendingUpload"} [fileInfo.status]
   * @param {boolean} [manyItemsToAdd]
   */
  addFileToList(fileInfo, manyItemsToAdd = false) {
    if (!Localizer.sharedLocalizer) {
      throw new Error();
    }

    const { key, filename, downloadUrl, size, uploaded, status } = fileInfo;

    const listElement = document.createElement("li");
    listElement.classList.add("file", "item");
    listElement.dataset.key = key;
    listElement.dataset.is = "file";

    this.#elementToFileInfoMap.set(listElement, fileInfo);

    const iconElement = document.createElement("span");
    iconElement.classList.add("icon");
    iconElement.dataset.l10nKey = "fileIcon";
    iconElement.setAttribute("aria-hidden", "true");

    if (!this.isSummary) {
      const detailsElement = document.createElement("details");
      const summaryElement = document.createElement("summary");
      summaryElement.dataset.l10nKey = "viewFileDetails viewFileDetails";
      summaryElement.dataset.l10nAttr = "title aria-label";
      detailsElement.appendChild(summaryElement);

      summaryElement.appendChild(iconElement);
      listElement.appendChild(detailsElement);
    } else {
      listElement.appendChild(iconElement);
    }

    switch (status) {
      case "uploading":
      case "pendingUpload": {
        const filenameElement = document.createElement("span");
        filenameElement.textContent = filename;
        listElement.appendChild(filenameElement);
        const labelElement = document.createElement("span");
        labelElement.classList.add("status-label");
        labelElement.dataset.l10nKey =
          status === "uploading"
            ? "fileUploadingItemTitleLabelAfter"
            : "filePendingUploadTitleLabelAfter";
        listElement.appendChild(labelElement);
        break;
      }
      default:
      case undefined: {
        if (this.isSummary) {
          const filenameElement = document.createElement("span");
          filenameElement.textContent = filename;
          listElement.appendChild(filenameElement);
        } else {
          const anchorElement = document.createElement("a");
          anchorElement.target = "_blank";
          if (downloadUrl) {
            anchorElement.href = downloadUrl;
          }
          anchorElement.textContent = filename;
          anchorElement.dataset.action = "download";
          listElement.appendChild(anchorElement);
        }
        break;
      }
    }

    if (!this.isSummary) {
      const fileMetadataElement = document.createElement("ul");
      fileMetadataElement.classList.add("metadata");
      const sizeMetadataElement = document.createElement("li");
      sizeMetadataElement.dataset.l10nKey = "metadataSize";
      const sizeString = size !== null ? getSizeString(size) : "-";
      sizeMetadataElement.dataset.l10nSubstitutions = JSON.stringify({
        size: sizeString,
      });
      fileMetadataElement.appendChild(sizeMetadataElement);

      if (uploaded) {
        const uploadedTimeMetadataElement = document.createElement("li");
        uploadedTimeMetadataElement.dataset.l10nKey = "metadataUploadedTime";
        uploadedTimeMetadataElement.dataset.l10nSubstitutions = JSON.stringify({
          date: new Intl.DateTimeFormat(Localizer.sharedLocalizer.locale, {
            dateStyle: "short",
            timeStyle: "short",
          }).format(uploaded),
        });
        fileMetadataElement.appendChild(uploadedTimeMetadataElement);
      }
      listElement.appendChild(fileMetadataElement);

      const actionsElement = document.createElement("div");
      actionsElement.classList.add("actions");
      listElement.appendChild(actionsElement);

      switch (status) {
        case "uploading":
        case "pendingUpload": {
          const cancelUploadButtonElement = document.createElement("button");
          cancelUploadButtonElement.classList.add(
            "action-button",
            "cancel-upload-item",
          );
          cancelUploadButtonElement.dataset.action = "cancel-upload";
          cancelUploadButtonElement.type = "button";
          cancelUploadButtonElement.dataset.l10nKey = "cancelUpload";
          actionsElement.appendChild(cancelUploadButtonElement);
          break;
        }
        default:
        case undefined: {
          if (!downloadUrl) {
            const downloadButtonElement = document.createElement("button");
            downloadButtonElement.classList.add(
              "action-button",
              "download-item",
            );
            downloadButtonElement.dataset.action = "start-download";
            downloadButtonElement.type = "button";
            downloadButtonElement.dataset.l10nKey = "download";
            actionsElement.appendChild(downloadButtonElement);

            const saveButtonElement = document.createElement("button");
            saveButtonElement.classList.add("action-button", "save-item");
            saveButtonElement.dataset.action = "save";
            saveButtonElement.type = "button";
            saveButtonElement.dataset.l10nKey = "save";
            actionsElement.appendChild(saveButtonElement);
          }

          const showDetailButtonElement = document.createElement("button");
          showDetailButtonElement.classList.add(
            "action-button",
            "show-detail-item",
          );
          showDetailButtonElement.dataset.action = "show-detail";
          showDetailButtonElement.type = "button";
          showDetailButtonElement.dataset.l10nKey = "showDetail";
          actionsElement.appendChild(showDetailButtonElement);

          const moveButtonElement = document.createElement("button");
          moveButtonElement.classList.add("action-button", "change-item");
          moveButtonElement.dataset.action = "change";
          moveButtonElement.type = "button";
          moveButtonElement.dataset.l10nKey = "change";
          actionsElement.appendChild(moveButtonElement);

          const deleteButtonElement = document.createElement("button");
          deleteButtonElement.classList.add("action-button", "delete-item");
          deleteButtonElement.dataset.action = "delete";
          deleteButtonElement.type = "button";
          deleteButtonElement.dataset.l10nKey = "delete";
          actionsElement.appendChild(deleteButtonElement);
          break;
        }
      }
    }

    const handle = new DropFileHandle(
      {
        element: listElement,
        onFileRemove: () => {
          if (!this.#elementToFileHandleMap.has(listElement)) {
            return;
          }
          const { size, status } =
            this.#elementToFileInfoMap.get(listElement) ?? {};
          this.#count--;
          if (size === undefined) {
            throw new Error();
          }
          if (status === undefined) {
            this.#totalSize -= size !== null ? size : 0;
          }

          this.onFileDidRemoveFromList?.({ key, filename, handle });
          this.#updateStatus();
        },
      },
      status,
    );
    this.#elementToFileHandleMap.set(listElement, handle);

    this.onFileDidAddToList?.({
      key,
      filename,
      handle,
    });

    this.#addItemToList(listElement, manyItemsToAdd);

    return handle;
  }

  /**
   * @param {Object} folderInfo
   * @param {string} folderInfo.key
   * @param {string} folderInfo.folderName
   * @param {boolean} [manyItemsToAdd]
   */
  addFolderToList({ key, folderName }, manyItemsToAdd = false) {
    const listElement = document.createElement("li");
    listElement.dataset.key = key;
    listElement.dataset.is = "folder";
    listElement.classList.add("folder", "item");

    const iconElement = document.createElement("span");
    iconElement.classList.add("icon");
    iconElement.dataset.l10nKey = "folderIcon";
    iconElement.setAttribute("aria-hidden", "true");

    listElement.appendChild(iconElement);

    const anchorElement = document.createElement("a");
    anchorElement.href = "#" + encodeURIComponent(key);
    anchorElement.dataset.action = "browse";
    anchorElement.dataset.key = key;
    anchorElement.dataset.folderName = folderName;
    anchorElement.textContent = folderName;

    listElement.appendChild(anchorElement);

    this.#addItemToList(listElement, manyItemsToAdd);
  }

  /**
   * @param {HTMLLIElement} listElement
   * @param {boolean} manyItemsToAdd
   */
  #addItemToList(listElement, manyItemsToAdd) {
    const key = listElement.dataset.key;
    const { size, status } = this.#elementToFileInfoMap.get(listElement) ?? {};

    if (key === undefined) {
      throw new Error();
    }
    if (size !== undefined && status === undefined) {
      this.#totalSize += size !== null ? size : 0;
    }

    if (manyItemsToAdd) {
      this.#count++;
      this.#element.append(listElement);
      return;
    }

    const referenceElement = /** @type {HTMLLIElement[]} */ (
      Array.from(this.#element.children)
    ).findLast(
      (element) =>
        element.dataset.is === listElement.dataset.is &&
        /** @type {string} */ (element.dataset.key) <= key,
    );
    if (referenceElement) {
      if (key === referenceElement.dataset.key) {
        const { status: existingItemStatus } =
          this.#elementToFileInfoMap.get(referenceElement) ?? {};
        if (this.#isStatusReplaceable(existingItemStatus, status)) {
          this.#element.replaceChild(listElement, referenceElement);
          if (listElement.dataset.is === "file") {
            this.#calculateTotalSize();
          }
        } else {
          this.#count++;
          this.#element.insertBefore(
            listElement,
            referenceElement.nextElementSibling,
          );
        }
      } else {
        this.#count++;
        this.#element.insertBefore(
          listElement,
          referenceElement.nextElementSibling,
        );
      }
    } else {
      this.#count++;
      if (listElement.dataset.is === "folder") {
        this.#element.insertBefore(
          listElement,
          this.#element.firstElementChild,
        );
      } else {
        const lastFolderListElement = /** @type {HTMLLIElement[]} */ (
          Array.from(this.#element.children)
        ).findLast((element) => element.dataset.is === "folder");
        if (lastFolderListElement) {
          this.#element.insertBefore(
            listElement,
            lastFolderListElement.nextElementSibling,
          );
        } else {
          this.#element.insertBefore(
            listElement,
            this.#element.firstElementChild,
          );
        }
      }
    }
    this.#updateStatus();
  }

  #calculateTotalSize() {
    this.#totalSize = 0;
    Array.prototype.forEach.call(this.#element.children, (element) => {
      const { size, status } = this.#elementToFileInfoMap.get(element) ?? {};
      if (size && status === undefined) {
        this.#totalSize += size;
      }
    });
  }

  manyItemsHasAdded() {
    this.#updateStatus();
  }

  #updateStatus() {
    if (!Localizer.sharedLocalizer) {
      throw new Error();
    }

    if (!this.#statusElement) {
      return;
    }

    if (this.isSummary) {
      this.#statusElement.dataset.l10nKey = "";
      return;
    }

    if (this.#count !== 0) {
      this.#statusElement.dataset.l10nKey =
        Localizer.sharedLocalizer.appendPluralRules(
          "listStatusItem",
          this.#count,
        );
      this.#statusElement.dataset.l10nSubstitutions = JSON.stringify({
        num: this.#count,
        totalSize: getSizeString(this.#totalSize),
      });
    } else {
      this.#statusElement.dataset.l10nKey = "listStatusNoItems";
      delete this.#statusElement.dataset.l10nSubstitutions;
    }
  }

  /**
   *
   * @param {"uploading"|"pendingUpload"|undefined} status
   * @param {"uploading"|"pendingUpload"|undefined} newStatus
   * @returns
   */
  #isStatusReplaceable(status, newStatus) {
    return (
      (status === "pendingUpload" && newStatus === "uploading") ||
      status === newStatus
    );
  }
}
