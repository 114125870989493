export default class PopImageController {
  /**
   * @param {object} delegate
   * @param {HTMLElement} delegate.container
   * @param {() => Promise<File[]>} delegate.getImages
   */
  constructor(delegate) {
    this.#delegate = delegate;
    this.#container = delegate.container;
  }

  static IMAGES_PREFIX = /** @type {const} */ (".drop/images/");

  #delegate;
  #container;

  /** @type {HTMLImageElement[]} */
  #images = [];

  #loggedIn = false;

  async loggedIn() {
    this.#loggedIn = true;
    const images = await this.#delegate.getImages();
    if (!this.#loggedIn) {
      return;
    }
    if (images.length === 0) {
      return;
    }

    const start = Math.floor(Math.random() * 360);
    this.#images = images.map((image, i) => {
      const img = document.createElement("img");
      img.src = URL.createObjectURL(image);
      img.classList.add("pop-image");

      const deg = start + (360 * i) / images.length;
      img.style.setProperty("--un-pop", `rotate(${deg - 30}deg)`);
      img.style.setProperty(
        "--pop",
        `rotate(${deg}deg) translateY(calc(var(--pop-shift) - ${Math.floor(Math.random() * 50)}px)) scale(2)`,
      );

      return img;
    });
    this.#container.append(...this.#images);

    await new Promise((resolve) => setTimeout(resolve, 100));
    if (!this.#loggedIn) {
      return;
    }
    document.body.classList.add("pop");
    await new Promise((resolve) => setTimeout(resolve, 1500));
    document.body.classList.remove("pop");
  }

  async loggedOut() {
    this.#loggedIn = false;
    this.#images.forEach((image) => {
      URL.revokeObjectURL(image.src);
      image.remove();
    });
    this.#images = [];
  }
}
