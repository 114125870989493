class Localizer {
  constructor() {
    const locale = this.#detectLocale();
    this.#setLocale(locale);
  }

  /** @type {Localizer|null} */
  static sharedLocalizer = null;

  /** @type {string} */
  #locale = "";

  /** @type {{[key: string]: string}} */
  currentStringMap = {};

  get locale() {
    return this.#locale;
  }

  set locale(locale) {
    locale = this.#detectLocale([locale]);
    this.#setLocale(locale);
  }

  /**
   * @param {string} locale
   */
  #setLocale(locale) {
    this.#locale = locale;
    this.currentStringMap = this.stringMaps[locale];

    if (typeof Intl.PluralRules === "function") {
      this.pluralRules = new Intl.PluralRules(locale);
    }
  }

  /** @type {{[key: string]: {[key: string]: string}}} */
  stringMaps = {
    "en-US": {
      username: "Username",
      password: "Password",
      login: "Login",
      done: "Done.",
      uploadStat:
        "Uploading: {{name}}, {{loaded}}/{{total}} ({{percent}}%), {{num}} file(s) remaining.",
      "uploadStat.one":
        "Uploading: {{name}}, {{loaded}}/{{total}} ({{percent}}%), {{num}} file remaining.",
      "uploadStat.other":
        "Uploading: {{name}}, {{loaded}}/{{total}} ({{percent}}%), {{num}} files remaining.",
      retryUploadStat:
        "Retry uploading: {{name}}, {{loaded}}/{{total}} ({{percent}}%), {{num}} file(s) remaining.",
      "retryUploadStat.one":
        "Retry uploading: {{name}}, {{loaded}}/{{total}} ({{percent}}%), {{num}} file remaining.",
      "retryUploadStat.other":
        "Retry uploading: {{name}}, {{loaded}}/{{total}} ({{percent}}%), {{num}} files remaining.",
      preparingUploadStat:
        "Preparing upload: {{name}}, {{num}} file(s) remaining.",
      "preparingUploadStat.one":
        "Preparing upload: {{name}}, {{num}} file remaining.",
      "preparingUploadStat.other":
        "Preparing upload: {{name}}, {{num}} files remaining.",
      unsupported: "Error: Browser unsupported.",
      delete: "Delete",
      statusInstruction: `Drop files or folders on the cloud, or click to select files.
  You may drop/select more files while uploading.`,
      incorrectUsernamePassword: "Incorrect username/password.",
      loginFirst: "You need to login first.",
      errorWithInfo:
        "The server returned the following error response:\n\n{{code}}: {{message}}",
      fileListError: "Unable to retrieve file list.",
      fileUploadError: "Upload file {{name}} failed.",
      fileUploadEncryptionError:
        "Upload file {{name}} failed, unable to read or encrypt.",
      fileUploadStartError: "Upload file {{name}} failed, unable to start.",
      fileUploadCompleteError: "Upload file {{name}} failed, unable to finish.",
      fileSizeExceedError:
        "Unable to upload, file {{name}} exceeded maximum file size.",
      deletionConfirmation: "Are you sure you want to delete file {{name}}?",
      fileDeleteError: "Delete file {{name}} failed.",
      fileGetError: "Downloading file {{name}} failed.",
      topLevelFolder: "Top Level Folder",
      listStatusLoading: "Loading Items...",
      listStatusError: "Loading Error",
      listStatusNoItems: "No Items",
      listStatusItem: "{{num}} Item(s) · {{totalSize}}",
      "listStatusItem.one": "{{num}} Item · {{totalSize}}",
      "listStatusItem.other": "{{num}} Items · {{totalSize}}",
      goToFolder: "Go to Folder",
      path: "Folder path",
      go: "Go",
      listStatusHidden: "😶‍🌫️",
      listStatusLocked: "🔒",
      selectFile: "Select Files to Upload",
      folderIcon: "🗂️",
      fileIcon: "📄",
      viewFileDetails: "View File Details",
      metadataSize: "Size: {{size}}",
      metadataUploadedTime: "Uploaded: {{date}}",
      subscribe: "🔔 Subscribe to file changes",
      unsubscribe: "🔕 Unsubscribe to file changes",
      unableToSetupSubscription: "Error: Unable to setup subscription.",
      unableToCancelSubscription: "Error: Unable to cancel subscription.",
      notificationTitleUnknown: "Unknown push message",
      notificationTitleFileCreated: "File {{name}} Uploaded",
      notificationBodyFileCreated:
        "File {{name}} was uploaded.\nLocation: {{location}}\nFile size: {{fileSize}}",
      notificationTitleFileRemoved: "File {{name}} Uploaded",
      notificationBodyUnknown:
        "Received an message that can't be handled. Please update the app by open it once.",
      notificationBodyFileRemoved:
        "File {{name}} was removed. Location: {{location}}",
      experimentMode: "Experiment Mode",
      fileUploadingItemTitleLabelAfter: " (Uploading...)",
      filePendingUploadTitleLabelAfter: " (Waiting for upload)",
      fileDownloadingTitleLabelAfter: " (Downloading...)",
      fileDownloadingTitleLabelWithProgressAfter:
        " (Downloading: {{loaded}}/{{total}} ({{percent}}%))",
      fileDownloadedTitleLabelAfter: " (Downloaded)",
      cancelUpload: "Cancel Upload",
      cancelUploadConfirmation:
        "Are you sure you want to cancel uploading {{filename}}?",
      change: "Change",
      changeFileDialogTitle: "Change File Name and Location",
      newFilename: "File Name",
      newFolderName: "File Location",
      confirmChangeFile: "Confirm Changes",
      cancelChangeFile: "Cancel",
      fileChangeUpdating: "Applying changes...",
      fileChangeError: "Change file {{name}} failed.",
      fileDownloadError: "Download file {{name}} failed.",
      showDetail: "Details",
      fileDetailMessage: `File name: {{filename}}
Location: {{path}}
Size: {{size}}
Media Type: {{contentType}}
Created: {{created}}
Modified: {{modified}}
Uploaded: {{uploaded}}
ETag: {{eTag}}
Encryption State: {{encryptedState}}
Stored File Name: {{storedFilename}}
Stored Location: {{storedPath}}
Stored Size: {{storedSize}}`,
      encryptedStateYes: "Encrypted (XSalsa20-Poly1305)",
      encryptedStateNo: "Not Encrypted",
      download: "Download",
      save: "Save",
    },
    "zh-TW": {
      username: "帳號",
      password: "密碼",
      login: "登入",
      done: "上傳結束。",
      uploadStat:
        "上傳中: {{name}}，{{loaded}}/{{total}} ({{percent}}%)，還有 {{num}} 個檔案。",
      "uploadStat.other":
        "上傳中: {{name}}，{{loaded}}/{{total}} ({{percent}}%)，還有 {{num}} 個檔案。",
      retryUploadStat:
        "重新上傳中: {{name}}，{{loaded}}/{{total}} ({{percent}}%)，還有 {{num}} 個檔案。",
      "retryUploadStat.other":
        "重新上傳中: {{name}}，{{loaded}}/{{total}} ({{percent}}%)，還有 {{num}} 個檔案。",
      "preparingUploadStat.other":
        "準備上傳中: {{name}}，還有 {{num}} 個檔案。",
      unsupported: "錯誤: 不支援的瀏覽器。",
      delete: "刪除",
      statusInstruction:
        "拖檔案或資料夾到雲上，或是按一下雲選擇檔案。上傳時可以繼續拖放或點選增加更多檔案。",
      incorrectUsernamePassword: "帳號密碼錯誤。",
      loginFirst: "您需要先登入。",
      errorWithInfo: "伺服器回傳下列錯誤訊息:\n\n{{code}}: {{message}}",
      fileListError: "無法取得檔案清單。",
      fileUploadError: "上傳檔案 {{name}} 失敗。",
      fileUploadEncryptionError: "上傳檔案 {{name}} 失敗，無法讀取或加密。",
      fileUploadStartError: "上傳檔案 {{name}} 失敗，無法啟動上傳。",
      fileUploadCompleteError: "上傳檔案 {{name}} 失敗，無法結束上傳。",
      fileSizeExceedError: "無法上傳，檔案 {{name}} 超過了最大上傳大小。",
      deletionConfirmation: "您確定要刪除檔案 {{name}} 嗎？",
      fileDeleteError: "刪除檔案 {{name}} 失敗。",
      fileGetError: "下載檔案 {{name}} 失敗。",
      topLevelFolder: "最上層資料夾",
      listStatusLoading: "載入項目中...",
      listStatusError: "載入失敗",
      listStatusNoItems: "沒有項目",
      listStatusItem: "{{num}} 個項目 · {{totalSize}}",
      "listStatusItem.other": "{{num}} 個項目 · {{totalSize}}",
      goToFolder: "開啟資料夾路徑",
      path: "資料夾路徑",
      go: "開啟",
      listStatusHidden: "😶‍🌫️",
      listStatusLocked: "🔒",
      selectFile: "選擇上傳檔案",
      folderIcon: "🗂️",
      fileIcon: "📄",
      viewFileDetails: "查看檔案細節",
      metadataSize: "檔案大小: {{size}}",
      metadataUploadedTime: "上傳時間: {{date}}",
      subscribe: "🔔 訂閱檔案變更",
      unsubscribe: "🔕 取消訂閱檔案變更",
      unableToSetupSubscription: "錯誤: 訂閱檔案變更失敗。",
      unableToCancelSubscription: "錯誤: 取消訂閱檔案變更失敗。",
      notificationTitleUnknown: "不明的推播訊息",
      notificationTitleFileCreated: "檔案 {{name}} 已上傳",
      notificationBodyFileCreated:
        "檔案 {{name}} 已上傳。\n位置: {{location}}\n檔案大小: {{fileSize}}",
      notificationTitleFileRemoved: "檔案 {{name}} 已刪除",
      notificationBodyUnknown:
        "收到了無法處理的訊息。請打開 app 一次更新一下。",
      notificationBodyFileRemoved:
        "檔案 {{name}} 已刪除。\n位置: {{location}}\n檔案大小: {{fileSize}}",
      experimentMode: "測試模式",
      fileUploadingItemTitleLabelAfter: " (上傳中...)",
      filePendingUploadTitleLabelAfter: " (等待上傳中)",
      fileDownloadingTitleLabelAfter: " (下載中...)",
      fileDownloadingTitleLabelWithProgressAfter:
        " (下載中: {{loaded}}/{{total}} ({{percent}}%))",
      fileDownloadedTitleLabelAfter: " (已下載)",
      cancelUpload: "取消上傳",
      cancelUploadConfirmation: "您確定要取消上傳 {{filename}}？",
      change: "變更",
      changeFileDialogTitle: "變更檔案名稱與位置",
      newFilename: "檔案名稱",
      newFolderName: "檔案位置",
      confirmChangeFile: "確認變更",
      cancelChangeFile: "取消",
      fileChangeUpdating: "變更檔案中...",
      fileChangeError: "變更檔案 {{name}} 失敗。",
      fileDownloadError: "下載檔案 {{name}} 失敗。",
      showDetail: "細節",
      fileDetailMessage: `檔案名稱: {{filename}}
位置: {{path}}
檔案大小: {{size}}
媒體類型: {{contentType}}
建立日期: {{created}}
修改日期: {{modified}}
上傳日期: {{uploaded}}
ETag: {{eTag}}
加密狀態: {{encryptedState}}
儲存名稱: {{storedFilename}}
儲存位置: {{storedPath}}
儲存大小: {{storedSize}}`,
      encryptedStateYes: "已加密 (XSalsa20-Poly1305)",
      encryptedStateNo: "未加密",
      download: "下載",
      save: "儲存",
    },
  };

  #detectLocale(userLocales = navigator.languages) {
    const supportedLocales = Object.keys(this.stringMaps);

    for (const userLocale of userLocales) {
      if (supportedLocales.includes(userLocale)) {
        return userLocale;
      }
    }

    const supportedLanguages = supportedLocales.map((locale) => {
      const index = locale.indexOf("-");
      return index !== -1 ? locale.substring(0, locale.indexOf("-")) : locale;
    });

    for (const [i, userLocale] of userLocales.entries()) {
      if (supportedLanguages.includes(userLocale)) {
        return supportedLocales[i];
      }
    }

    const userLanguages = userLocales.map((locale) => {
      const index = locale.indexOf("-");
      return index !== -1 ? locale.substring(0, locale.indexOf("-")) : locale;
    });

    for (const [i, userLanguage] of userLanguages.entries()) {
      if (supportedLanguages.includes(userLanguage)) {
        return supportedLocales[i];
      }
    }

    return Object.keys(this.stringMaps)[0];
  }

  /**
   *
   * @param {string} l10nKey
   * @param {{[key: string]: string}} [substitutions]
   * @returns
   */
  get(l10nKey, substitutions) {
    let str = this.currentStringMap[l10nKey];
    if (substitutions) {
      for (const [subKey, value] of Object.entries(substitutions)) {
        str = str.replaceAll(`{{${subKey}}}`, value);
      }
    }
    return str;
  }

  /**
   * @param {string} l10nKey
   * @param {number} num
   */
  appendPluralRules(l10nKey, num) {
    if (!this.pluralRules) {
      return l10nKey;
    }

    return `${l10nKey}.${this.pluralRules.select(num)}`;
  }
}

export default Localizer;
