import ListController from "./list-controller.js";
import { splitKey } from "./paths.js";

/**
 * @typedef {import("./file-store.js").FolderInfo} FolderInfo
 * @typedef {import("./file-store.js").FileInfo} FileInfo
 * @typedef {import("./file-store.js").ErrorInfo} ErrorInfo
 */

export default class ChangeFileUI {
  /**
   * @param {object} delegate
   * @param {HTMLDialogElement} delegate.dialogElement
   * @param {(prefix: string) => Promise<[false|(FolderInfo|FileInfo)[], ErrorInfo?]>} delegate.listFiles
   * @param {() => [File, string][]} delegate.getPendingFiles
   * @param {() => File|null} delegate.getCurrentFile
   */
  constructor(delegate) {
    this.#listController = new ListController({
      listElement: /** @type {HTMLUListElement} */ (
        delegate.dialogElement.querySelector(".item-list")
      ),
      listStatusElement: /** @type {HTMLSpanElement} */ (
        delegate.dialogElement.querySelector(".list-status")
      ),
      breadcrumbElement: /** @type {HTMLUListElement} */ (
        delegate.dialogElement.querySelector(".breadcrumb")
      ),
      showDetailFile() {
        throw new Error();
      },
      deleteFile() {
        throw new Error();
      },
      getFile() {
        throw new Error();
      },
      changeFile() {
        throw new Error();
      },
      listFiles: (prefix) => {
        return delegate.listFiles(prefix);
      },
      cancelFile() {
        throw new Error();
      },
      async getStoredSize(size) {
        return size;
      },
      pushState() {},
      getPendingUploadFiles() {
        return delegate.getPendingFiles();
      },
      async getDownloadUrl(key) {
        return "";
      },
      getCurrentUploadingFile() {
        return delegate.getCurrentFile();
      },
      toggleHiddenFiles() {},
      toggleExperiments() {},
      togglePlaintextListing() {},
      showHiddenFiles: false,
      isSummary: true,
      prefixChanged: () => {
        this.#newPrefix = this.#listController.listPrefix;
        this.#handleValuesChange();
      },
    });

    this.#dialogElement = delegate.dialogElement;
    this.#dialogElement.addEventListener("close", () => this.close());

    this.#filenameInputElement = /** @type {HTMLInputElement} */ (
      delegate.dialogElement.querySelector(".filename")
    );
    this.#actionsFormElement = /** @type {HTMLFormElement} */ (
      delegate.dialogElement.querySelector(".drop-dialog-actions")
    );
    this.#dialogStatusElement = /** @type {HTMLSpanElement} */ (
      delegate.dialogElement.querySelector(".dialog-status")
    );
    this.#changeButtonElement = /** @type {HTMLButtonElement} */ (
      delegate.dialogElement.querySelector(".change-form")
    );
    this.#actionsFormElement.addEventListener("submit", (event) =>
      this.#handleFormSubmit(event),
    );
    this.#actionsFormElement.addEventListener("reset", (event) =>
      this.#handleFormReset(event),
    );
    this.#filenameInputElement.addEventListener("input", () => {
      this.#newFilename = this.#filenameInputElement.value.trim();
      this.#handleValuesChange();
    });
  }

  #dialogElement;
  #filenameInputElement;
  #actionsFormElement;
  #changeButtonElement;
  #dialogStatusElement;

  #listController;

  /** @type {((newKey: string|null) => void)|null} */
  #resolveOpenDialog = null;

  #prefix = "";
  #filename = "";

  #newPrefix = "";
  #newFilename = "";

  /**
   * @param {string} key
   * @param {string} [newKey]
   */
  async open(key, newKey) {
    if (this.#resolveOpenDialog) {
      throw new Error();
    }

    this.#actionsFormElement.querySelectorAll("button").forEach((element) => {
      element.disabled = false;
    });

    delete this.#dialogStatusElement.dataset.l10nKey;
    const { prefix, filename } = splitKey(key);

    this.#prefix = prefix;
    this.#filename = filename;

    if (newKey) {
      const { prefix: newPrefix, filename: newFilename } = splitKey(newKey);
      this.#newPrefix = newPrefix;
      this.#newFilename = newFilename;
    } else {
      this.#newPrefix = prefix;
      this.#newFilename = filename;
    }
    this.#handleValuesChange();

    this.#filenameInputElement.value = filename;

    this.#dialogElement.showModal();
    this.#listController.goToPrefix(prefix);
    return new Promise((resolve) => {
      this.#resolveOpenDialog = (newKey) => resolve(newKey);
    });
  }

  close() {
    this.#resolveOpenDialog?.(null);
    this.#resolveOpenDialog = null;
    this.#dialogElement.close();
  }

  /**
   *
   * @param {SubmitEvent} event
   */
  #handleFormSubmit(event) {
    event.preventDefault();

    if (
      this.#newFilename === this.#filename &&
      this.#newPrefix === this.#prefix
    ) {
      throw new Error();
    }

    if (!this.#resolveOpenDialog) {
      throw new Error();
    }

    this.#actionsFormElement.querySelectorAll("button").forEach((element) => {
      element.disabled = true;
    });
    this.#resolveOpenDialog(this.#newPrefix + this.#newFilename);
    this.#resolveOpenDialog = null;
    this.#dialogStatusElement.dataset.l10nKey = "fileChangeUpdating";
  }

  /**
   * @param {Event} event
   */
  #handleFormReset(event) {
    event.preventDefault();

    if (!this.#resolveOpenDialog) {
      throw new Error();
    }

    this.#resolveOpenDialog(null);
    this.#resolveOpenDialog = null;
    this.#dialogElement.close();
  }

  #handleValuesChange() {
    this.#changeButtonElement.disabled =
      this.#prefix === this.#newPrefix && this.#filename === this.#newFilename;
    delete this.#dialogStatusElement.dataset.l10nKey;
  }
}
