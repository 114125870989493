import { FilePartReader } from "./file-part-reader.js";
import UploadPartSizeAdjuster from "./upload-part-size-adjuster.js";

/**
 * QueueUpload object includes the queue and the actual upload machinery.
 **/
class QueueUpload {
  xhrTimeout = 30 * 1e3;
  xhrRetries = 5;

  /**
   * Max file size allowed, in bytes.
   * false-y value to disable the check.
   */
  maxFileSize = 0;

  /**
   * Use PUT or POST for uploading
   * @type {"POST"|"PUT"}
   */
  method = "POST";

  /**
   * key: values to accompany the file when upload.
   * Only applicable when the HTTP_METHOD is POST.
   * @type {{[key: string]: string}}
   */
  formData = {};

  /**
   * post field name to use for the file.
   * Only applicable when the HTTP_METHOD is POST.
   */
  postName = "file";

  /**
   * headers to accompany the file when upload.
   * @type {{[key: string]: string}}
   */
  headers = {};

  /**
   * url to upload to.
   */
  url = "";

  /**
   * When a file starts uploading.
   * @type {((file: File) => Promise<false|any>)|null}
   **/
  onFileStart = null;
  /**
   * When a single-part upload starts.
   * Must configure upload-specific XHR parameters before resolving the promise.
   * @type {((file: File) => Promise<void>)|null}
   **/
  onUploadStart = null;
  /**
   * When a multi-part upload starts.
   * Must configure upload-specific XHR parameters before resolving the promise.
   * @type {((file: File, index: number) => Promise<void>)|null}
   **/
  onUploadPartStart = null;
  /**
   * When upload progress is made.
   * @type {((file: File, xhr: XMLHttpRequest, loaded: number, total: number, retry: number) => void)|null}
   **/
  onUploadProgress = null;
  /**
   * When upload progress is made.
   * @type {((file: File, xhr: XMLHttpRequest, loaded: number, total: number, retry: number) => void)|null}
   **/
  onUploadPartRetry = null;
  /**
   * When a multi-part upload completes.
   * Must handle error, if any. Return `false` to stop the file upload of this file.
   * Return value will be collected and send back to `onFileComplete`.
   * @type {((file: File, xhr: XMLHttpRequest) => Promise<false|any>)|null}
   **/
  onUploadPartComplete = null;
  /**
   * When a single-part upload completes.
   * Must handle error, if any. Return `false` to stop the file upload of this file.
   * @type {((file: File, xhr: XMLHttpRequest) => Promise<false|void>)|null}
   **/
  onUploadComplete = null;
  /**
   * When a file completes uploading.
   * Will only be called if the upload is successful (i.e, `onUploadPartComplete()` or `onUploadComplete` did not return `false`.)
   * @type {((file: File, totalParts: number, partsData?: any[]) => Promise<void>)|null}
   **/
  onFileComplete = null;

  /**
   * When a single-part upload completes.
   * Must handle error, if any. Return `false` to stop the file upload of this file.
   * @type {((file: File) => Promise<void>)|null}
   **/
  onCurrentUploadCancelled = null;

  /** @type {((name: string) => void)|null} */
  onMaxFileSizeExceed = null;

  isSupported = !!(
    window.FormData &&
    window.XMLHttpRequest &&
    Array.prototype.forEach
  );

  /**
   * Private Boolean to know if the queue is currently uploading
   */
  #isUploading = false;

  /**
   * Private Array to place files
   * @type {File[]}
   */
  #queue = [];

  static #adjuster = new UploadPartSizeAdjuster();

  static partSize = 10 * 2 ** 20;
  static get partSizeMultiple() {
    return QueueUpload.#adjuster.partSizeMultiple;
  }
  static get minPartSizeMultiple() {
    return QueueUpload.#adjuster.minPartSizeMultiple;
  }

  /** @type {File|null} */
  #currentFile = null;

  getCurrentFile() {
    return this.#currentFile;
  }

  getPendingFiles() {
    return /** @type {File[]} */ (
      this.#currentFile ? [this.#currentFile] : []
    ).concat(this.#queue);
  }

  getQueueLength() {
    return this.#queue.length;
  }

  /**
   * @param {File[]} fileList a [object FileList] containing files to upload
   */
  addQueue(fileList) {
    if (!fileList || !this.isSupported) return;

    Array.prototype.forEach.call(fileList, (file) => {
      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.onMaxFileSizeExceed?.(file.name);
        return;
      }

      this.#queue.push(file);
    });

    if (!this.#isUploading) {
      this.#startNextUpload();
    }
  }

  /**
   *
   * @param {File} file
   */
  async cancelFile(file) {
    if (this.#currentFile === file) {
      this.#currentFile = null;
      this.#currentAbortController?.abort();
      this.#currentAbortController = null;
      await this.onCurrentUploadCancelled?.(file);

      this.#startNextUpload();
    }
    if (!this.#queue.includes(file)) {
      return;
    }
    this.#queue.splice(this.#queue.indexOf(file), 1);
  }

  /**
   * @param {File} file
   * @returns
   */
  isMultipart(file) {
    return QueueUpload.partSize < file.size;
  }

  /** @type {WeakMap<File, File|{ readonly size: number, toStream(): ReadableStream, toBlob(): Promise<Blob> }>} */
  uploadFileMap = new WeakMap();

  async #startNextUpload() {
    const file = this.#queue.shift();
    this.#currentFile = file ?? null;

    if (!file) {
      this.#isUploading = false;
      return;
    }

    this.#isUploading = true;

    const result = await this.onFileStart?.(file);
    if (this.#currentFile !== file) {
      return;
    }

    if (result === false) {
      await this.#startNextUpload();
      return;
    }

    if (!this.isMultipart(file)) {
      this.#continueUpload(file);
    } else {
      this.#continueMultipartUpload(file);
    }
  }

  /** @type {{ abort: () => void }|null} */
  #currentAbortController = null;

  /**
   * @param {File} file
   */
  async #continueUpload(file) {
    const uploadFile = this.uploadFileMap.get(file) ?? file;
    const fileSource =
      uploadFile instanceof File ? uploadFile : await uploadFile.toBlob();

    /**
     * @param {number} [retries]
     */
    const continueFileUpload = async (retries = this.xhrRetries) => {
      await this.onUploadStart?.(file);
      if (this.#currentFile !== file) {
        return;
      }

      const xhr = new XMLHttpRequest();
      this.#currentAbortController = xhr;
      xhr.open(this.method, this.url);
      for (const name in this.headers) {
        xhr.setRequestHeader(name, this.headers[name]);
      }
      this.onUploadProgress?.(
        file,
        xhr,
        0,
        uploadFile.size,
        this.xhrRetries - retries,
      );

      xhr.upload.onprogress = (evt) => {
        if (this.#currentFile !== file) {
          return;
        }
        this.onUploadProgress?.(
          file,
          xhr,
          evt.loaded,
          evt.total,
          this.xhrRetries - retries,
        );
      };

      xhr.timeout = this.xhrTimeout;

      xhr.onloadend = async () => {
        if (this.#currentFile !== file) {
          return;
        }
        this.#currentAbortController = null;
        if ((xhr.status === 0 || xhr.status >= 500) && retries !== 0) {
          retries--;

          this.onUploadProgress?.(
            file,
            xhr,
            0,
            uploadFile.size,
            this.xhrRetries - retries,
          );

          await new Promise((resolve) =>
            setTimeout(resolve, 100 * 2 ** (this.xhrRetries - retries)),
          );

          if (this.#currentFile !== file) {
            return;
          }

          continueFileUpload(retries);
          return;
        }

        const result = await this.onUploadComplete?.(file, xhr);
        if (this.#currentFile !== file) {
          return;
        }
        if (result !== false) {
          await this.onFileComplete?.(file, 1);
          if (this.#currentFile !== file) {
            return;
          }
        }

        await this.#startNextUpload();
      };

      if (this.method === "POST") {
        const formData = new FormData();
        formData.append(this.postName, fileSource);

        for (const name in this.formData) {
          formData.append(name, this.formData[name]);
        }

        xhr.send(formData);
      } else {
        xhr.send(fileSource);
      }
    };
    await continueFileUpload();
  }

  /**
   * @param {File} file
   */
  async #continueMultipartUpload(file) {
    const uploadFile = this.uploadFileMap.get(file) ?? file;
    const filePartSource =
      uploadFile instanceof File ? uploadFile : uploadFile.toStream();
    const partReader = new FilePartReader(filePartSource, QueueUpload.partSize);
    /** @type {any[]} */
    const partsData = [];
    let loadedSize = 0;

    let restartCount = 0;

    /**
     * @param {Blob} filePart
     * @param {number} index
     * @param {number} [retries]
     */
    const continueFilePartUpload = async (
      filePart,
      index,
      retries = this.xhrRetries,
    ) => {
      await this.onUploadPartStart?.(file, index);
      if (this.#currentFile !== file) {
        return;
      }
      const xhr = new XMLHttpRequest();
      this.#currentAbortController = xhr;

      xhr.open(this.method, this.url);
      for (const name in this.headers) {
        xhr.setRequestHeader(name, this.headers[name]);
      }
      this.onUploadProgress?.(
        file,
        xhr,
        loadedSize,
        uploadFile.size,
        restartCount + this.xhrRetries - retries,
      );

      /**
       * @param {ProgressEvent} evt
       */
      xhr.upload.onprogress = (evt) => {
        if (this.#currentFile !== file) {
          return;
        }
        this.onUploadProgress?.(
          file,
          xhr,
          loadedSize + evt.loaded,
          uploadFile.size,
          restartCount + this.xhrRetries - retries,
        );
      };

      xhr.timeout = this.xhrTimeout;

      xhr.onloadend = async () => {
        if (this.#currentFile !== file) {
          return;
        }
        if (xhr.status === 0 || xhr.status >= 500) {
          if (
            QueueUpload.partSizeMultiple !== QueueUpload.minPartSizeMultiple
          ) {
            this.onUploadPartRetry?.(
              file,
              xhr,
              loadedSize,
              uploadFile.size,
              restartCount + this.xhrRetries - retries,
            );

            QueueUpload.#adjuster.updatePartSize(false);
            partReader.partSizeMultiple = QueueUpload.partSizeMultiple;
            restartCount++;
            partReader.restartFilePart();
            return;
          }

          if (retries !== 0) {
            retries--;
            this.#currentAbortController = null;

            this.onUploadPartRetry?.(
              file,
              xhr,
              loadedSize,
              uploadFile.size,
              restartCount + this.xhrRetries - retries,
            );

            this.onUploadProgress?.(
              file,
              xhr,
              loadedSize,
              uploadFile.size,
              restartCount + this.xhrRetries - retries,
            );

            await new Promise((resolve) =>
              setTimeout(resolve, 100 * 2 ** (this.xhrRetries - retries)),
            );
            if (this.#currentFile !== file) {
              return;
            }

            continueFilePartUpload(filePart, index, retries);
            return;
          }
        }

        const partData = await this.onUploadPartComplete?.(file, xhr);
        if (this.#currentFile !== file) {
          return;
        }
        if (partData === false) {
          await this.#startNextUpload();

          return;
        }

        partsData[index] = partData;
        loadedSize += filePart.size;

        if (
          filePart.size ===
          QueueUpload.partSize * QueueUpload.partSizeMultiple
        ) {
          QueueUpload.#adjuster.updatePartSize(true);
          partReader.partSizeMultiple = QueueUpload.partSizeMultiple;
        }
        restartCount = 0;
        partReader.startNextFilePart();
      };

      xhr.send(filePart);
    };

    partReader.onFilePart = continueFilePartUpload;
    partReader.onFileEnd = async (totalPartCount) => {
      if (this.#currentFile !== file) {
        return;
      }

      await this.onFileComplete?.(file, totalPartCount, partsData);
      if (this.#currentFile !== file) {
        return;
      }

      await this.#startNextUpload();
    };
    partReader.partSizeMultiple = QueueUpload.partSizeMultiple;
    partReader.start();
  }
}

export default QueueUpload;
