import DropSubscribeUI from "./drop-subscribe-ui.js";
import WebPush from "./web-push.js";
import Localizer from "./localizer.js";

/**
 * @typedef {PushSubscriptionJSON & { date: string; userAgent: string; updatedDate: string; }} ServerPushSubscriptionJSON
 **/

export default class WebPushController {
  constructor() {
    this.subscribeUI = new DropSubscribeUI(
      /** @type {HTMLButtonElement} */ (
        document.getElementById("push-subscription")
      ),
    );
    this.webPush = new WebPush();

    this.webPush.onStateChange =
      /**
       * @param {import("./web-push.js").WebPushState} state
       * @param {import("./web-push.js").WebPushState} previousState
       */
      (state, previousState) => {
        if (!this.#isLoggedIn) {
          return;
        }
        this.subscribeUI.updateUIWithWebPushState(state);
      };
    this.subscribeUI.onSubscribe = () => this.#handleSubscribe();
    this.subscribeUI.onUnsubscribe = () => this.#handleUnsubscribe();
    this.webPush.onReceivingClientSubscription = () =>
      this.#handleReceivingClientSubscription();
    this.webPush.onRemovingClientSubscription = () =>
      this.#handleRemovingClientSubscription();
  }

  static VAPID_KEY_PATH = "/.drop/web-push/vapid-key.pub";
  static SUBSCRIPTION_FILE_PATH_TEMPLATE = "/.drop/web-push/{{auth}}.json";

  #isLoggedIn = false;

  /** @type {(() => Promise<WebPush["vapidPublicKey"]>)|null} */
  onCheckingWebPushAvailability = null;

  /** @type {((path: string) => Promise<any>)|null} */
  onGettingPushSubscriptionDetail = null;
  /** @type {((path: string, subscription: ServerPushSubscriptionJSON) => Promise<boolean>)|null} */
  onAddingPushSubscriptionDetail = null;
  /** @type {((path: string, subscription: PushSubscriptionJSON) => Promise<boolean>)|null} */
  onRemovingPushSubscriptionDetail = null;

  async loggedIn() {
    this.#isLoggedIn = true;

    if ("requestIdleCallback" in window) {
      await new Promise((resolve) => window.requestIdleCallback(resolve));
    } else {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (!this.#isLoggedIn) {
      return;
    }

    await navigator.serviceWorker.register("./sw.js", {
      type: "module",
      updateViaCache: "none",
    });

    if (!this.#isLoggedIn) {
      return;
    }

    const key = await this.onCheckingWebPushAvailability?.();
    if (!this.#isLoggedIn || !key) {
      return;
    }

    this.webPush.vapidPublicKey = key;
    this.webPush.checkState();
  }

  loggedOut() {
    this.#isLoggedIn = false;
    this.webPush.vapidPublicKey = null;
    this.subscribeUI.updateUIWithWebPushState("unknown");
  }

  async #handleSubscribe() {
    _paq.push(["trackEvent", "UI", "WebPush", "Subscribe"]);
    if (!this.#isLoggedIn) {
      return;
    }

    const result = await this.webPush.subscribe();
    if (!result) {
      _paq.push(["trackEvent", "UI", "WebPush", "SubscribeError"]);
      alert(Localizer.sharedLocalizer?.get("unableToSetupSubscription"));
      return;
    }
    _paq.push(["trackEvent", "UI", "WebPush", "SubscribeSuccessful"]);
  }

  async #handleUnsubscribe() {
    _paq.push(["trackEvent", "UI", "WebPush", "Unsubscribe"]);
    if (!this.#isLoggedIn) {
      return;
    }

    const result = await this.webPush.unsubscribe();
    if (!result) {
      _paq.push(["trackEvent", "UI", "WebPush", "UnsubscribeError"]);
      alert(Localizer.sharedLocalizer?.get("unableToCancelSubscription"));
      return;
    }
    _paq.push(["trackEvent", "UI", "WebPush", "UnsubscribeSuccessful"]);
  }

  async #handleReceivingClientSubscription() {
    const pushSubscription = this.webPush.pushSubscription;
    if (!pushSubscription) {
      throw new Error();
    }

    if (!this.#isLoggedIn) {
      return false;
    }

    const json = pushSubscription.toJSON();
    const auth = json.keys?.auth;
    if (!auth) {
      return false;
    }
    const serverPath =
      WebPushController.SUBSCRIPTION_FILE_PATH_TEMPLATE.replace(
        "{{auth}}",
        auth,
      );

    const serverJson = /** @type {ServerPushSubscriptionJSON|null} */ (
      await this.onGettingPushSubscriptionDetail?.(serverPath)
    );

    /** @type {ServerPushSubscriptionJSON} */
    const newServerSubscription = {
      date: serverJson?.date ?? new Date().toISOString(),
      updatedDate: new Date().toISOString(),
      userAgent: navigator.userAgent,
      endpoint: json.endpoint,
      expirationTime: json.expirationTime,
      keys: json.keys,
    };

    return (
      (await this.onAddingPushSubscriptionDetail?.(
        serverPath,
        newServerSubscription,
      )) ?? false
    );
  }

  async #handleRemovingClientSubscription() {
    if (!this.#isLoggedIn) {
      return false;
    }
    const pushSubscription = this.webPush.pushSubscription;
    if (!pushSubscription) {
      throw new Error();
    }
    const json = pushSubscription.toJSON();
    const auth = json.keys?.auth;
    if (!auth) {
      return false;
    }
    const serverPath =
      WebPushController.SUBSCRIPTION_FILE_PATH_TEMPLATE.replace(
        "{{auth}}",
        auth,
      );

    if (!(await this.onRemovingPushSubscriptionDetail?.(serverPath, json))) {
      return false;
    }

    return true;
  }
}
