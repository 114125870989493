/**
 * Given an path, this is the breakdowns:
 *
 *       /abc/def/ghi.ext
 *       ^^^^^^^^^^^^^^^^  uri
 *        ^^^^^^^^^^^^^^^  key/object name
 *        ^^^^^^^^         prefix
 *            ^^^          folder name
 *                ^^^^^^^  filename
 *
 * For a file on the root path:
 *
 *       /abc.ext
 *       ^^^^^^^^          uri
 *        ^^^^^^^          key/object name
 *                         prefix (empty string)
 *                         folder name (empty string)
 *        ^^^^^^^          filename
 *
 * And finally, a key of a folder/list is represented by its prefix.
 */

class KeyComponent {
  /**
   * @param {string} key
   */
  constructor(key) {
    this.#key = key;
  }
  #key;

  /** @type {string|undefined} */
  #prefix;
  get prefix() {
    if (this.#prefix === undefined) {
      this.#prefix = this.#key.includes("/")
        ? this.#key.substring(0, this.#key.lastIndexOf("/") + 1)
        : "";
    }
    return this.#prefix;
  }

  get filename() {
    return this.#key.substring(this.prefix.length);
  }

  /** @type {string[]|undefined} */
  #folderNames;
  get folderNames() {
    if (this.#folderNames === undefined) {
      const prefix = this.prefix;
      if (prefix !== "") {
        this.#folderNames = [
          "🪣",
          ...prefix.substring(0, prefix.length - 1).split("/"),
        ];
      } else {
        this.#folderNames = ["🪣"];
      }
    }
    return this.#folderNames;
  }

  get humanizedLocation() {
    return this.folderNames.join(" ⏵ ");
  }
}

/**
 * @param {string} key
 */
export const splitKey = (key) => {
  return new KeyComponent(key);
};

/**
 * @param {string} key
 */
export const uriFromKey = (key) => "/" + key;

/**
 * @param {string} uri
 */
export const keyFromUri = (uri) => uri.substring(1);

/**
 * @param {{prefix: string, filename: string}} keyComponent
 */
export const keyFromComponents = ({ prefix, filename }) => {
  return prefix + filename;
};
