export default class EncryptionController {
  constructor() {}

  /** @type {Promise<import("@fyears/rclone-crypt").Cipher>|null} */
  #cipher = null;

  async #getCipherInstance() {
    if (!this.#cipher) {
      this.#cipher = (async () => {
        const { Cipher } = await import(
          /* webpackChunkName: "encryption-libraries" */ "./encryption-libraries.js"
        );
        const cipher = new Cipher("base32");
        cipher.key(this.#password, this.#salt);
        return cipher;
      })();
    }
    return this.#cipher;
  }

  #password = "";
  #salt = "";

  /**
   *
   * @param {string} password
   * @param {string} salt
   */
  setConfig(password, salt) {
    this.#password = password;
    this.#salt = salt;
    this.#getCipherInstance();
  }

  clearConfig() {
    this.#password = "";
    this.#salt = "";
    this.#cipher = null;
  }

  /**
   * @param {string} name
   * @returns
   */
  async encryptName(name) {
    const cipher = await this.#getCipherInstance();
    return cipher.encryptFileName(name);
  }

  /**
   * @param {string} name
   * @returns
   */
  async decryptName(name) {
    const cipher = await this.#getCipherInstance();
    try {
      return await cipher.decryptFileName(name);
    } catch (error) {
      if (error instanceof SyntaxError) {
        return null;
      }
      throw error;
    }
  }

  /**
   * @param {File} file
   */
  async encryptFile(file) {
    const { encryptFile } = await import(
      /* webpackChunkName: "encryption-libraries" */ "./encryption-libraries.js"
    );
    const cipher = await this.#getCipherInstance();

    try {
      return encryptFile(file, cipher);
    } catch (e) {
      return null;
    }
  }

  /**
   * @param {ReadableStream<Uint8Array>} stream
   */
  async decryptFile(stream) {
    const { decryptFile } = await import(
      /* webpackChunkName: "encryption-libraries" */ "./encryption-libraries.js"
    );
    const cipher = await this.#getCipherInstance();

    try {
      return decryptFile(stream, cipher);
    } catch (e) {
      return null;
    }
  }

  /**
   * @param {number} size
   */
  async encryptedFileSize(size) {
    const { encryptedSize } = await import(
      /* webpackChunkName: "encryption-libraries" */ "./encryption-libraries.js"
    );
    return encryptedSize(size);
  }

  /**
   * @param {number} size
   */
  async decryptedFileSize(size) {
    const { decryptedSize } = await import(
      /* webpackChunkName: "encryption-libraries" */ "./encryption-libraries.js"
    );
    try {
      return decryptedSize(size);
    } catch (e) {
      return null;
    }
  }
}
