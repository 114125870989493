/**
 * @template {any} T
 * @param {T} obj
 */
const NonNullable = (obj) => /** @type {NonNullable<T>} */ (obj);

export default class DownloadController {
  /**
   * @param {Object} delegate
   * @param {(key: string, delegate: { onDownloadProgress: (loaded: number, total: number|null) => void }) => Promise<[null|File]>} delegate.getFile
   */
  constructor(delegate) {
    this.#delegate = delegate;
  }

  #delegate;

  /** @type {Set<string> } */
  #pendingDownloadKeys = new Set();

  /** @type {Map<string, import("./drop-item-list.js").DropFileHandle> } */
  #pendingDownloadHandleMap = new Map();

  /** @type {Map<string, string>} */
  #fileUrlMap = new Map();

  static downloadedFileTimeout = 1e3 * 60 * 20; // 20 minutes

  /** @type {Map<string, ReturnType<setTimeout>>} */
  #revokeUrlTimerMap = new Map();

  /**
   * @param {string} key
   * @param {import("./drop-item-list.js").DropFileHandle} handle
   */
  async downloadFile(key, handle) {
    this.#pendingDownloadKeys.add(key);
    this.#pendingDownloadHandleMap.set(key, handle);
    handle.setPendingDownload(true);
    const [result] = await this.#delegate.getFile(key, {
      onDownloadProgress: (loaded, total) => {
        if (total === null) {
          return;
        }
        handle.setPendingDownload(true, loaded, total);
      },
    });

    if (!this.#pendingDownloadKeys.has(key)) {
      return;
    }

    const pendingHandle = this.#pendingDownloadHandleMap.get(key);
    pendingHandle?.setPendingDownload(false);
    this.#pendingDownloadHandleMap.delete(key);
    this.#pendingDownloadKeys.delete(key);
    if (result) {
      const url = URL.createObjectURL(result);
      pendingHandle?.setDownloadBlobUrl(url);
      this.#fileUrlMap.set(key, url);
      if (!pendingHandle) {
        this.#delayedRevokeUrl(key);
      }
    }
  }

  /**
   * @param {string} key
   * @param {import("./drop-item-list.js").DropFileHandle} handle
   */
  async fileDidAddToList(key, handle) {
    clearTimeout(this.#revokeUrlTimerMap.get(key));
    this.#revokeUrlTimerMap.delete(key);

    if (this.#pendingDownloadKeys.has(key)) {
      handle.setPendingDownload(true);
      this.#pendingDownloadHandleMap.set(key, handle);
    }
    if (this.#fileUrlMap.has(key)) {
      handle.setDownloadBlobUrl(NonNullable(this.#fileUrlMap.get(key)));
    }
  }

  /**
   * @param {string} key
   * @param {import("./drop-item-list.js").DropFileHandle} handle
   */
  async fileDidRemoveFromList(key, handle) {
    if (this.#pendingDownloadHandleMap.has(key)) {
      this.#pendingDownloadHandleMap.delete(key);
    }

    if (this.#fileUrlMap.has(key)) {
      this.#delayedRevokeUrl(key);
    }
  }

  /**
   * @param {string} key
   */
  revokeUrl(key) {
    clearTimeout(this.#revokeUrlTimerMap.get(key));
    const url = this.#fileUrlMap.get(key);
    if (url) {
      URL.revokeObjectURL(url);
    }
    this.#fileUrlMap.delete(key);
    this.#pendingDownloadKeys.delete(key);
    this.#revokeUrlTimerMap.delete(key);
  }

  /**
   * @param {string} key
   */
  #delayedRevokeUrl(key) {
    const timer = setTimeout(() => {
      const url = this.#fileUrlMap.get(key);
      if (url) {
        URL.revokeObjectURL(url);
      }
      this.#fileUrlMap.delete(key);
      this.#revokeUrlTimerMap.delete(key);
    }, DownloadController.downloadedFileTimeout);
    this.#revokeUrlTimerMap.set(key, timer);
  }
}
