import DeletionController from "./deletion-controller.js";
import DownloadController from "./download-controller.js";
import DOMLocalizer from "./dom-localizer.js";
import DropItemList from "./drop-item-list.js";
import DropBreadcrumb from "./drop-breadcrumb.js";

/**
 * @typedef {import("./file-store.js").FolderInfo} FolderInfo
 * @typedef {import("./file-store.js").FileInfo} FileInfo
 * @typedef {import("./file-store.js").ErrorInfo} ErrorInfo
 */

export default class ListController {
  /**
   * @param {object} delegate
   * @param {HTMLUListElement} delegate.listElement
   * @param {HTMLSpanElement} [delegate.listStatusElement]
   * @param {HTMLUListElement} delegate.breadcrumbElement
   * @param {(key: string) => Promise<[boolean]>} delegate.showDetailFile
   * @param {(key: string) => Promise<[boolean]>} delegate.deleteFile
   * @param {(key: string, delegate: { onDownloadProgress: (loaded: number, total: number|null) => void }) => Promise<[null|File]>} delegate.getFile
   * @param {(key: string) => Promise<[boolean]>} delegate.changeFile
   * @param {(prefix: string) => Promise<[false|(FolderInfo|FileInfo)[], ErrorInfo?]>} delegate.listFiles
   * @param {(key: string, file: File) => void} delegate.cancelFile
   * @param {(size: number) => Promise<number|null>} delegate.getStoredSize
   * @param {(hash: string) => void} delegate.pushState
   * @param {() => [File, string][]} delegate.getPendingUploadFiles
   * @param {(key: string) => Promise<string>} delegate.getDownloadUrl
   * @param {() => File|null} delegate.getCurrentUploadingFile
   * @param {() => void} delegate.toggleHiddenFiles
   * @param {() => void} delegate.toggleExperiments
   * @param {() => void} delegate.togglePlaintextListing
   * @param {boolean} delegate.showHiddenFiles
   * @param {boolean} [delegate.isSummary]
   * @param {() => void} [delegate.prefixChanged]
   */
  constructor(delegate) {
    this.#delegate = delegate;

    this.#deletionController = new DeletionController({
      deleteFile: (key) => delegate.deleteFile(key),
    });

    this.#downloadController = new DownloadController({
      getFile: async (key, downloadDelegate) => {
        const result = await delegate.getFile(key, downloadDelegate);
        if (!result) {
          const localizer = DOMLocalizer.sharedLocalizer;
          if (!localizer) {
            throw new Error();
          }
          alert("fileDownloadError");
        }

        return result;
      },
    });

    this.#dropItemList = new DropItemList(
      delegate.listElement,
      delegate.listStatusElement,
    );
    this.#dropItemList.isSummary =
      delegate.isSummary !== undefined ? delegate.isSummary : false;
    this.#dropItemList.onDeleteFile = (deletionInfo) =>
      this.#handleDeleteFile(deletionInfo);
    this.#dropItemList.onChangeFile = (changeFileInfo) =>
      this.#handleChangeFile(changeFileInfo);
    this.#dropItemList.onShowDetailFile = (changeFileInfo) =>
      this.#handleShowDetailFile(changeFileInfo);
    this.#dropItemList.onCancelUploadFile = (cancelUploadFileInfo) =>
      this.#handleCancelUploadFile(cancelUploadFileInfo);
    this.#dropItemList.onStartDownloadFile = (startDownloadFileInfo) =>
      this.#handleStartDownloadFile(startDownloadFileInfo);
    this.#dropItemList.onBrowseFolder = (browseFolderInfo) =>
      this.#handleBrowserFolder(browseFolderInfo);
    this.#dropItemList.onFileDidAddToList = (info) =>
      this.#handleFileDidAddToList(info);
    this.#dropItemList.onFileDidRemoveFromList = (info) =>
      this.#handleFileDidRemoveFromList(info);

    this.#dropBreadcrumb = new DropBreadcrumb(delegate.breadcrumbElement);
    this.#dropBreadcrumb.onBrowseFolder = (browseFolderInfo) =>
      this.#handleBrowserFolder(browseFolderInfo);
    this.#dropBreadcrumb.onGoToFolder = (path) => this.#handleGoToFolder(path);
  }

  #getListPrefixFromHash() {
    if (!window.location.hash.startsWith("#!")) {
      return "";
    }

    // Clean up the human path input to avoid confusions.
    const path = decodeURIComponent(window.location.hash.substring(2))
      .replace("\\", "/")
      .replace(/\/+/, "/")
      .replace(/\/$/, "")
      .split("/")
      .map((part) => part.trim())
      .join("/");

    return path === "" || path.endsWith("/") ? path : `${path}/`;
  }

  /**
   * @param {string} prefix
   */
  goToPrefix(prefix) {
    this.#listPrefix = prefix;
    this.#dropBreadcrumb.updateBreadcrumb(this.#listPrefix);
    this.#updateFileList();
    this.#delegate.prefixChanged?.();
  }

  handleCurrentUrlHash() {
    this.goToPrefix(this.#getListPrefixFromHash());
  }

  /**
   * @param {string} key
   */
  revokeDownloadUrl(key) {
    this.#downloadController.revokeUrl(key);
  }

  #delegate;

  #deletionController;
  #downloadController;
  #dropItemList;
  #dropBreadcrumb;

  /** @type {string} Current folder key, without leading slash, with trailing slash */
  get listPrefix() {
    return this.#listPrefix;
  }

  #listPrefix = "";

  /** @type {WeakMap<File, import("./drop-item-list.js").DropFileHandle>} Map b/t file to upload and handle */
  #fileToHandleMap = new WeakMap();

  /** @type {WeakMap<import("./drop-item-list.js").DropFileHandle, File>} Map b/t file to upload and handle */
  #handleToFileMap = new WeakMap();

  /**
   * @param {{key: string, filename: string, handle: import("./drop-item-list.js").DropFileHandle }} deletionInfo
   */
  async #handleDeleteFile({ handle, key }) {
    const result = await this.#deletionController.deleteFile(key, handle);
    if (result) {
      this.#downloadController.revokeUrl(key);
    }
  }

  /**
   * @param {{key: string, filename: string, handle: import("./drop-item-list.js").DropFileHandle }} deletionInfo
   */
  async #handleFileDidAddToList({ key, handle }) {
    this.#deletionController.fileDidAddToList(key, handle);
    this.#downloadController.fileDidAddToList(key, handle);
  }

  /**
   * @param {{key: string, filename: string, handle: import("./drop-item-list.js").DropFileHandle }} deletionInfo
   */
  async #handleFileDidRemoveFromList({ key, handle }) {
    this.#downloadController.fileDidRemoveFromList(key, handle);
  }

  /**
   * @param {{key: string, filename: string, handle: import("./drop-item-list.js").DropFileHandle }} deletionInfo
   */
  async #handleChangeFile({ handle, filename, key }) {
    handle.setPendingDeletion(true);
    const [result] = await this.#delegate.changeFile(key);
    if (result) {
      handle.remove();
      this.#downloadController.revokeUrl(key);
    } else {
      handle.setPendingDeletion(false);
    }
  }

  /**
   * @param {{key: string, filename: string, handle: import("./drop-item-list.js").DropFileHandle }} deletionInfo
   */
  async #handleShowDetailFile({ handle, key }) {
    await this.#delegate.showDetailFile(key);
  }

  /**
   * @param {{key: string, filename: string, handle: import("./drop-item-list.js").DropFileHandle }} cancelUploadInfo
   */
  async #handleCancelUploadFile({ key, handle }) {
    const file = this.#handleToFileMap.get(handle);
    if (!file) {
      throw new Error();
    }
    this.#delegate.cancelFile(key, file);
    handle.remove();
  }

  /**
   * @param {{key: string, filename: string, handle: import("./drop-item-list.js").DropFileHandle }} cancelUploadInfo
   */
  async #handleStartDownloadFile({ key, handle }) {
    this.#downloadController.downloadFile(key, handle);
  }

  /**
   * @param {{key: string, folderName: string}} browseFolderInfo
   */
  #handleBrowserFolder(browseFolderInfo) {
    const key = browseFolderInfo.key;
    this.#delegate.pushState(
      `#!${encodeURI(key === "" ? key : key.substring(0, key.length - 1))}`,
    );
    const isReload = this.#listPrefix === key;
    this.#listPrefix = key;
    this.#dropBreadcrumb.updateBreadcrumb(this.#listPrefix);
    this.#updateFileList(isReload);
    if (!isReload) {
      this.#delegate.prefixChanged?.();
    }
  }

  /**
   * @param {string} path
   */
  #handleGoToFolder(path) {
    if (path === "..hidden/") {
      this.#delegate.toggleHiddenFiles();
      return;
    }

    if (path === "..experiments/") {
      this.#delegate.toggleExperiments();
      return;
    }

    if (path === "..plaintext/") {
      this.#delegate.togglePlaintextListing();
      return;
    }

    this.#listPrefix += path;
    this.#delegate.pushState(
      `#!${encodeURI(this.#listPrefix.substring(0, this.#listPrefix.length - 1))}`,
    );
    this.#dropBreadcrumb.updateBreadcrumb(this.#listPrefix);
    this.#updateFileList();
    this.#delegate.prefixChanged?.();
  }

  /**
   * @param {boolean} [isReload]
   */
  async #updateFileList(isReload = false) {
    const prefix = this.#listPrefix;
    const withHiddenFiles = this.#delegate.showHiddenFiles;

    this.#dropItemList.setListLoading(isReload);
    const [results, errorInfo] = await this.#delegate.listFiles(
      this.#listPrefix,
    );

    if (
      prefix !== this.#listPrefix ||
      withHiddenFiles !== this.#delegate.showHiddenFiles
    ) {
      // Cancelled by another updateFileList call.
      return;
    }

    if (!results) {
      const localizer = DOMLocalizer.sharedLocalizer;
      if (!localizer) {
        throw new Error();
      }
      if (errorInfo) {
        this.#dropItemList.setListLoadingError();
        alert(
          localizer.get("fileListError") +
            "\n" +
            localizer.get("errorWithInfo", {
              code: errorInfo.code,
              message: errorInfo.message,
            }),
        );
      } else {
        this.#dropItemList.setListLoadingError();
        alert(localizer.get("fileListError"));
      }
      return;
    }

    /** @type {((import("./file-store.js").FileInfo & { downloadUrl: string }))[]} */
    const fileInfoWithLinksResults = [];
    /** @type {import("./file-store.js").FolderInfo[]} */
    const folderInfoResults = [];

    for (const info of results) {
      switch (info.is) {
        case "file":
          fileInfoWithLinksResults.push({
            is: info.is,
            key: info.key,
            filename: info.filename,
            uploaded: info.uploaded,
            size: info.size,
            storedSize: info.storedSize,
            downloadUrl: await this.#delegate.getDownloadUrl(info.key),
          });
          break;
        case "folder":
          folderInfoResults.push(info);
          break;
      }
    }

    this.#dropItemList.resetList();

    for (const info of folderInfoResults) {
      this.addFolderToList(info, undefined, true);
    }

    for (const info of fileInfoWithLinksResults) {
      this.#addFileToList(info, undefined, true);
    }

    this.#dropItemList.manyItemsHasAdded();

    await this.addPendingFilesToList(this.#delegate.getPendingUploadFiles());
  }

  /**
   * @param {[File, string][]} pendingFiles
   */
  async addPendingFilesToList(pendingFiles) {
    for (const [file, prefix] of pendingFiles) {
      if (!prefix.startsWith(this.#listPrefix)) {
        continue;
      }

      if (prefix !== this.listPrefix) {
        const subPrefix = prefix.substring(this.listPrefix.length);
        const folderName = subPrefix.substring(0, subPrefix.indexOf("/"));
        this.addFolderToList({
          is: "folder",
          key: this.#listPrefix + folderName + "/",
          folderName,
        });
      } else {
        this.addFileToList(
          {
            is: "file",
            key: this.#listPrefix + file.name,
            filename: file.name,
            uploaded: null,
            size: file.size,
            storedSize: await this.#delegate.getStoredSize(file.size),
            downloadUrl: "",
          },
          this.#delegate.getCurrentUploadingFile() === file
            ? "uploading"
            : "pendingUpload",
          file,
        );
      }
    }
  }

  /**
   * @param {FileInfo & { downloadUrl: string }} fileInfo
   * @param {"uploading"|"pendingUpload"} [status]
   * @param {boolean} [manyItemsToAdd]
   * @returns {import("./drop-item-list.js").DropFileHandle|undefined}
   */
  #addFileToList(fileInfo, status, manyItemsToAdd = false) {
    const showHiddenFiles = this.#delegate.showHiddenFiles;
    const { key, filename, size, storedSize, uploaded, downloadUrl } = fileInfo;
    if (!showHiddenFiles && filename.startsWith(".")) {
      return;
    }

    return this.#dropItemList.addFileToList(
      {
        key,
        filename,
        size,
        storedSize,
        uploaded,
        downloadUrl,
        status,
      },
      manyItemsToAdd,
    );
  }

  /**
   * @param {FolderInfo} folderInfo
   * @param {"uploading"|"pendingUpload"} [status]
   * @param {boolean} [manyItemsToAdd]
   */
  addFolderToList(folderInfo, status, manyItemsToAdd = false) {
    const showHiddenFiles = this.#delegate.showHiddenFiles;
    if (!showHiddenFiles && folderInfo.folderName.startsWith(".")) {
      return;
    }

    this.#dropItemList.addFolderToList(
      {
        key: folderInfo.key,
        folderName: folderInfo.folderName,
      },
      manyItemsToAdd,
    );
  }

  /**
   * @param {FileInfo & { downloadUrl: string }} fileInfo
   * @param {"uploading"|"pendingUpload"} [status]
   * @param {File} [pendingFile]
   */
  addFileToList(fileInfo, status, pendingFile) {
    const handle = this.#addFileToList(fileInfo, status);
    if (pendingFile && handle) {
      this.#fileToHandleMap.set(pendingFile, handle);
      this.#handleToFileMap.set(handle, pendingFile);
    }
  }

  /**
   * @param {File} file
   */
  removePendingFileFromList(file) {
    this.#fileToHandleMap.get(file)?.remove();
  }

  /**
   * @param {File} file
   * @param {boolean} disabled
   */
  setPendingFileCancelUploadDisabled(file, disabled) {
    this.#fileToHandleMap.get(file)?.setCancelUploadDisabled(true);
  }

  openGoToForm() {
    this.#dropBreadcrumb.openGoToForm();
  }

  reloadFileList() {
    this.#updateFileList();
  }

  loggedOut() {
    this.#dropItemList.resetList();
    this.#dropBreadcrumb.emptyBreadcrumb();
  }
}
