/**
 * @template {any} T
 * @param {T} obj
 */
const NonNullable = (obj) => /** @type {NonNullable<T>} */ (obj);

export default class DeletionController {
  /**
   * @param {Object} delegate
   * @param {(key: string) => Promise<[boolean]>} delegate.deleteFile
   */
  constructor(delegate) {
    this.#delegate = delegate;
  }

  #delegate;

  /** @type {Map<string, import("./drop-item-list.js").DropFileHandle> } */
  #pendingDeletionHandleMap = new Map();

  /**
   * @param {string} key
   * @param {import("./drop-item-list.js").DropFileHandle} handle
   */
  async deleteFile(key, handle) {
    this.#pendingDeletionHandleMap.set(key, handle);
    handle.setPendingDeletion(true);
    const [result] = await this.#delegate.deleteFile(key);

    const pendingHandle = NonNullable(this.#pendingDeletionHandleMap.get(key));
    if (result) {
      pendingHandle.remove();
    } else {
      pendingHandle.setPendingDeletion(false);
    }
    this.#pendingDeletionHandleMap.delete(key);

    return result;
  }

  /**
   * @param {string} key
   * @param {import("./drop-item-list.js").DropFileHandle} handle
   */
  async fileDidAddToList(key, handle) {
    if (!this.#pendingDeletionHandleMap.has(key)) {
      return;
    }
    handle.setPendingDeletion(true);
    this.#pendingDeletionHandleMap.set(key, handle);
  }
}
