import { FilePartReader } from "./file-part-reader.js";
import { keyFromUri, splitKey } from "./paths.js";

/**
 * @template {any} T
 * @param {T} obj
 */
const NonNullable = (obj) => /** @type {NonNullable<T>} */ (obj);

/**
 * @typedef {Object} FileInfo
 * @property {"file"} is
 * @property {string} key Full path to file without leading slash.
 * @property {string} filename File name
 * @property {Date|null} uploaded
 * @property {number|null} size
 * @property {number|null} storedSize
 */

/**
 * @typedef {Object} FolderInfo
 * @property {"folder"} is
 * @property {string} key Full path to folder without leading slash, and with trailing slash.
 * @property {string} folderName Folder name
 */

/**
 * @typedef {Object} Stat
 * // {{contentType?: string, size?: number, uploaded?: Date, eTag?: string}}
 * @property {string} [contentType]
 * @property {number} [size]
 * @property {number} [storedSize]
 * @property {Date} [modified]
 * @property {Date} [created]
 * @property {Date} [uploaded]
 * @property {string} [eTag]
 */

/**
 * @typedef {{code: string, message: string}} ErrorInfo
 */

/**
 * @param {ArrayBuffer} buf
 */
const bufferToHex = (buf) =>
  [...new Uint8Array(buf)].map((n) => n.toString(16).padStart(2, "0")).join("");

/**
 * @param {BufferSource} data
 * @param {BufferSource} key
 */
const hmacSha256Sign = async (data, key) => {
  const algorithm = { name: "HMAC", hash: "SHA-256" };
  return await crypto.subtle.sign(
    algorithm.name,
    await crypto.subtle.importKey("raw", key, algorithm, false, ["sign"]),
    data,
  );
};

/**
 * @param {number|string|null} val
 * @returns
 */
const parseDate = (val) => {
  // null value check
  if (val === null || Number.isNaN(val)) {
    return;
  }
  const date = new Date(val);
  // Invalid Date check
  if (Number.isNaN(date.valueOf())) {
    return;
  }
  return date;
};

/**
 * Encode string as documented as `UriEncode()` in AWS Sig V4 documentation.
 *
 * Specifically,
 *
 * * URI encode every byte except the unreserved characters: 'A'-'Z', 'a'-'z', '0'-'9', '-', '.', '_', and '~'.
 * * The space character is a reserved character and must be encoded as "%20" (and not as "+").
 *
 * @see https://docs.aws.amazon.com/AmazonS3/latest/API/sig-v4-header-based-auth.html#create-signature-presign-entire-payload
 *
 * @param {string} str
 * @param {boolean} [isObjectName]
 */
const uriEncode = (str, isObjectName = false) => {
  return str
    .split("")
    .map((char) => {
      const code = char.charCodeAt(0);

      if (
        (code >= 0x41 && code <= 0x5a) || // A-Z
        (code >= 0x61 && code <= 0x7a) || // a-z
        (code >= 0x30 && code <= 0x39) || // 0-9
        code === 0x2d || // -
        code === 0x2e || // .
        code === 0x5f || // _
        code === 0x7e // ~
      ) {
        return char;
      }

      if (
        isObjectName &&
        code === 0x2f // /
      ) {
        return char;
      }

      if (code <= 0xff) {
        return `%${char.charCodeAt(0).toString(16).toUpperCase().padStart(2, "0")}`;
      }
      return encodeURIComponent(char);
    })
    .join("");
};

class RetryError extends Error {
  /**
   * @param {ErrorInfo} [errorInfo]
   */
  constructor(errorInfo) {
    super("Retry");
    this.errorInfo = errorInfo;
  }
}

class FileStoreBase {
  /** @type {import("./config-store.js").ConfigStoreBase["config"]} */
  config = null;

  apiRetries = 0;

  /** @type {((name: string) => Promise<string>)|null} */
  onNameEncryption = null;

  /**
   * @param {string} name
   */
  async encryptName(name) {
    return this.onNameEncryption ? this.onNameEncryption(name) : name;
  }

  /** @type {((name: string) => Promise<string|null>)|null} */
  onNameDecryption = null;

  /**
   * @param {string} name
   */
  async decryptName(name) {
    return this.onNameDecryption ? this.onNameDecryption(name) : name;
  }

  /** @type {((size: number) => Promise<number|null>)|null} */
  onSizeEncryption = null;

  /**
   * @param {number} size
   */
  encryptSize(size) {
    return this.onSizeEncryption ? this.onSizeEncryption(size) : size;
  }

  /** @type {((size: number) => Promise<number|null>)|null} */
  onSizeDecryption = null;

  /**
   * @param {number} size
   */
  decryptSize(size) {
    return this.onSizeDecryption ? this.onSizeDecryption(size) : size;
  }

  /**
   * @param {string} uri
   * @param {Date} expireDate
   * @return {Promise<string>}
   */
  async getDownloadUrl(uri, expireDate) {
    throw new Error("Not implemented.");
  }

  mtimeHeaderName = "";
  btimeHeaderName = "";

  /**
   * @overload
   * @param {string} uri
   * @param {"json"} type
   * @returns {Promise<any|null>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {"text"} [type]
   * @returns {Promise<string|null>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {"file"} [type]
   * @param {{ onDownloadProgress: (loaded: number, total: number|null) => void }} [delegate]
   * @returns {Promise<File|null>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {"buffer"} [type]
   * @returns {Promise<ArrayBuffer|null>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {"stream"} [type]
   * @param {{ onDownloadProgress: (loaded: number, total: number|null) => void }} [delegate]
   * @returns {Promise<{ type?: string; lastModified?: number; stream: ReadableStream<Uint8Array>}|null>}
   */
  /**
   * @param {string} uri
   * @param {"json"|"text"|"file"|"buffer"|"stream"} [type]
   * @param {{ onDownloadProgress: (loaded: number, total: number|null) => void }} [delegate]
   * @returns {Promise<any|string|File|ArrayBuffer|{ type: string; lastModified: number; stream: ReadableStream<Uint8Array>}|null>}
   */
  async getFile(uri, type = "text", delegate) {
    const expireDate = new Date(Date.now() + 60_000);
    try {
      const response = await fetch(await this.getDownloadUrl(uri, expireDate));

      if (response.status >= 300) {
        return null;
      }

      const contentLengthString = response.headers.get("Content-Length");
      const contentLength = contentLengthString
        ? parseInt(contentLengthString)
        : null;

      if (type === "json" || type === "text") {
        return await response[type]();
      }

      if (type === "buffer") {
        return response.arrayBuffer();
      }

      if (type === "stream") {
        let length = 0;
        delegate?.onDownloadProgress(length, contentLength);
        const bodyStream = response.body;

        if (!bodyStream) {
          return null;
        }

        return {
          type: response.headers.get("Content-Type") ?? undefined,
          lastModified: parseDate(
            parseInt(response.headers.get(this.mtimeHeaderName) ?? ""),
          )?.getTime(),
          stream: bodyStream.pipeThrough(
            new TransformStream({
              transform(chunk, controller) {
                length += chunk.byteLength;
                delegate?.onDownloadProgress(length, contentLength);
                controller.enqueue(chunk);
              },
            }),
          ),
        };
      }

      const reader = response.body?.getReader();
      if (!reader) {
        return null;
      }

      /** @type {Uint8Array[]} */
      const blobParts = [];
      let length = 0;
      delegate?.onDownloadProgress(length, contentLength);
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        length += value.byteLength;
        delegate?.onDownloadProgress(length, contentLength);
        blobParts.push(value);
      }

      const { filename } = splitKey(keyFromUri(uri));
      return new File(blobParts, filename, {
        type: response.headers.get("Content-Type") ?? undefined,
        lastModified: parseDate(
          parseInt(response.headers.get(this.mtimeHeaderName) ?? ""),
        )?.getTime(),
      });
    } catch (error) {
      return null;
    }
  }

  /**
   * @overload
   * @param {string} uri
   * @param {any} content
   * @param {"json"} type
   * @returns {Promise<[boolean, ErrorInfo?]>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {string} content
   * @param {"text"} [type]
   * @returns {Promise<[boolean, ErrorInfo?]>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {File} content
   * @param {"file"} [type]
   * @returns {Promise<[boolean, ErrorInfo?]>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {BufferSource} content
   * @param {"buffer"} [type]
   * @returns {Promise<[boolean, ErrorInfo?]>}
   */
  /**
   * @param {string} uri
   * @param {any|string|File|BufferSource} content
   * @param {"json"|"text"|"file"|"buffer"} [type]
   * @returns {Promise<[boolean, ErrorInfo?]>}
   */
  async setFile(uri, content, type = "text") {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @param {{[key: string]: string}} [extraHeaders]
   * @return {Promise<{url: string, headers: {[key: string]: string}, method: "PUT"|"POST"}>}
   */
  async getUploadAuthorizationInfo(uri, file, extraHeaders) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async createMultipartUpload(uri, file) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @param {number} partNumber
   * @return {Promise<{url: string, headers: {[key: string]: string}, method: "PUT"|"POST"}>}
   */
  async getUploadPartAuthorizationInfo(uri, file, partNumber) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @param {number} totalPartCount
   * @param {any[]} partsData
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async completeMultipartUpload(uri, file, totalPartCount, partsData) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async abortMultipartUpload(uri, file) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {XMLHttpRequest} xhr
   * @return {{success: true}|{success: false, errorInfo?: ErrorInfo}}
   */
  handleUploadComplete(xhr) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {XMLHttpRequest} xhr
   * @return {{success: true, data: any}|{success: false, errorInfo?: ErrorInfo}}
   */
  handleUploadPartComplete(xhr) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} [folderPath]
   * @return {Promise<[false|(FolderInfo|FileInfo)[], ErrorInfo?]>}
   */
  async listFiles(folderPath) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async deleteFile(uri) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @return {Promise<[false|Stat, ErrorInfo?]>}
   */
  async statFile(uri) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {string} newUri
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async copyFile(uri, newUri) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {string} newUri
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async moveFile(uri, newUri) {
    throw new Error("Not implemented.");
  }
}

class AmazonS3FileStore extends FileStoreBase {
  AWS_BUCKET_URL = "%protocol://%bucketName.s3.amazonaws.com%uri";

  /**
   * @override
   */
  apiRetries = 5;

  constructor() {
    super();
    this.awsTimeOffset = 0;
  }

  /** @type {WeakMap<File, { uploadId?: string, abortController?: AbortController, cancelled?: boolean }>} */
  #fileUploadDataMap = new WeakMap();

  /**
   * @override
   * @param {string} uri
   * @param {Date} expireDate
   */
  async getDownloadUrl(uri, expireDate) {
    if (!this.config) {
      throw new Error();
    }

    const { region, accessKeyId } = this.config;
    const date = this._getAWSDate();
    const dateString = `${date.getUTCFullYear()}${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}${date.getUTCDate().toString().padStart(2, "0")}`;
    const dateTimeString = `${dateString}T${date
      .getUTCHours()
      .toString()
      .padStart(2, "0")}${date
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}${date.getUTCSeconds().toString().padStart(2, "0")}Z`;

    let expiresIn;
    if (this.awsTimeOffset === 0) {
      expiresIn = Math.floor((expireDate.getTime() - Date.now()) / 1000);
    } else {
      expiresIn = Math.floor(
        (expireDate.getTime() + this.awsTimeOffset - Date.now()) / 1000,
      );
    }
    let url = await this._getAWSBucketObjectURL(uri);

    const host = new URL(await this._getAWSBucketObjectURL()).host;

    const canonicalRequestString =
      /* HTTPMethod */ "GET\n" +
      /* CanonicalURI */ `${uriEncode(await this.encryptName(uri), true)}\n` +
      /* CanonicalQueryString */ "X-Amz-Algorithm=AWS4-HMAC-SHA256" +
      `&X-Amz-Credential=${uriEncode(`${accessKeyId}/${dateString}/${region}/s3/aws4_request`)}` +
      `&X-Amz-Date=${dateTimeString}` +
      `&X-Amz-Expires=${expiresIn}` +
      "&X-Amz-SignedHeaders=host" +
      "\n" +
      /* CanonicalHeaders */ `host:${host}\n\n` +
      /* SignedHeaders */ `host\n` +
      /* HashedPayload */ "UNSIGNED-PAYLOAD";

    const textEncoder = new TextEncoder();

    const stringToSign =
      "AWS4-HMAC-SHA256" +
      "\n" +
      dateTimeString +
      "\n" +
      `${dateString}/${region}/s3/aws4_request` +
      "\n" +
      bufferToHex(
        await crypto.subtle.digest(
          "SHA-256",
          textEncoder.encode(canonicalRequestString),
        ),
      );

    const signingKey = await hmacSha256Sign(
      textEncoder.encode("aws4_request"),
      await hmacSha256Sign(
        textEncoder.encode("s3"),
        await hmacSha256Sign(
          textEncoder.encode(region),
          await hmacSha256Sign(
            textEncoder.encode(dateString),
            textEncoder.encode("AWS4" + this.config.secretAccessKey),
          ),
        ),
      ),
    );

    url +=
      "?X-Amz-Algorithm=AWS4-HMAC-SHA256" +
      `&X-Amz-Credential=${uriEncode(`${accessKeyId}/${dateString}/${region}/s3/aws4_request`)}` +
      `&X-Amz-Date=${dateTimeString}` +
      `&X-Amz-Expires=${expiresIn}` +
      "&X-Amz-SignedHeaders=host" +
      `&X-Amz-Signature=${bufferToHex(
        await hmacSha256Sign(textEncoder.encode(stringToSign), signingKey),
      )}`;

    return url;
  }

  /**
   * Run `executeCall()` for a number of times until it returns
   * successfully. When it throws it will be called again after
   * some exponential delays. Returned value must confirmed to type.
   *
   * @template {any} T
   * @param {() => Promise<[false|T, ErrorInfo?]>} executeCall
   * @param {number} [retries]
   * @return {Promise<[false|T, ErrorInfo?]>}
   */
  async #retryCalls(executeCall, retries = this.apiRetries) {
    try {
      return await executeCall();
    } catch (error) {
      if (retries !== 0) {
        retries--;
        await new Promise((resolve) =>
          setTimeout(resolve, 100 * 2 ** (this.apiRetries - retries)),
        );
        return this.#retryCalls(executeCall, retries);
      }

      if (error instanceof RetryError) {
        return [false, error.errorInfo];
      }
      return [false];
    }
  }

  static uploadPartSize = 10 * 2 ** 20;
  static copyPartSize = 1 * 2 ** 30;

  /**
   * @override
   * @param {string} uri
   * @param {any|string|File|BufferSource} content
   * @param {"json"|"text"|"file"|"buffer"} [type]
   * @param {{[key: string]: string}} [extraHeaders]
   * @returns {Promise<[boolean, ErrorInfo?]>}
   */
  async setFile(uri, content, type = "text", extraHeaders = {}) {
    const filename = uri.substring(uri.lastIndexOf("/") + 1);
    const file =
      type === "file"
        ? /** @type {File} */ (content)
        : new File(
            [
              type === "buffer"
                ? content
                : new TextEncoder().encode(
                    type === "text"
                      ? content
                      : JSON.stringify(content, null, 2),
                  ),
            ],
            filename,
            {
              type:
                type === "buffer"
                  ? "application/octet-stream"
                  : type === "text"
                    ? "text/plain"
                    : "application/json",
            },
          );

    if (file.size <= AmazonS3FileStore.uploadPartSize) {
      /**
       * @returns {Promise<[boolean, ErrorInfo?]>}
       */
      const executeCall = async () => {
        const { url, headers, method } = await this.getUploadAuthorizationInfo(
          uri,
          file,
          extraHeaders,
        );
        const response = await fetch(url, {
          method,
          headers,
          body: file,
        });

        if (response.status >= 300) {
          return [false];
        }

        this.#parseXMLDocument(await response.text());
        return [true];
      };
      return this.#retryCalls(executeCall);
    }

    const [result, errorInfo] = await this.createMultipartUpload(
      uri,
      file,
      extraHeaders,
    );
    if (!result) {
      return [false, errorInfo];
    }

    return new Promise((resolve) => {
      const slicer = new FilePartReader(file, AmazonS3FileStore.uploadPartSize);
      /** @type {{eTag: string}[]} */
      const partsData = [];
      slicer.onFilePart =
        /**
         * @param {Blob} filePart
         * @param {number} index
         */
        async (filePart, index) => {
          const { url, headers, method } =
            await this.getUploadPartAuthorizationInfo(
              uri,
              file,
              index + 1,
              extraHeaders,
            );

          /**
           * @returns {Promise<[true]>}
           */
          const executeCall = async () => {
            const response = await fetch(url, {
              method,
              headers,
              body: filePart,
            });

            const eTag = response.headers.get("ETag");
            if (!eTag) {
              throw new Error();
            }

            partsData.push({
              eTag,
            });

            this.#parseXMLDocument(await response.text());
            slicer.startNextFilePart();
            return [true];
          };
          const [result, errorInfo] = await this.#retryCalls(executeCall);
          if (!result) {
            resolve([result, errorInfo]);
          }
        };

      /**
       * @param {number} totalPartCount
       */
      slicer.onFileEnd = async (totalPartCount) => {
        const [result, errorInfo] = await this.completeMultipartUpload(
          uri,
          file,
          totalPartCount,
          partsData,
          extraHeaders,
        );
        if (!result) {
          resolve([result, errorInfo]);
          return;
        }
        resolve([true]);
      };

      slicer.start();
    });
  }

  /**
   * @override
   * @param {string} uri
   * @param {File} file
   * @param {{[key: string]: string}} [extraHeaders]
   * @return {Promise<{url: string, headers: {[key: string]: string}, method: "PUT"|"POST"}>}
   */
  async getUploadAuthorizationInfo(uri, file, extraHeaders) {
    const [url, headers] = await Promise.all([
      this._getAWSBucketObjectURL(uri),
      this._getAWSAuthorizationHeader(
        "PUT",
        uri,
        "",
        {
          // JS has no access to system btime.
          // [this.btimeHeaderName]: file.lastModified.toString(),
          [this.mtimeHeaderName]: file.lastModified.toString(),
          ...extraHeaders,
        },
        "UNSIGNED-PAYLOAD",
      ),
    ]);
    return {
      url,
      headers,
      method: "PUT",
    };
  }

  /**
   * @override
   * @param {string} uri
   * @param {File} file
   * @param {{[key: string]: string}} [extraHeaders]
   */
  async createMultipartUpload(uri, file, extraHeaders = {}) {
    if (this.#fileUploadDataMap.get(file)) {
      throw new Error("");
    }

    const queryString = "uploads=";
    const url = (await this._getAWSBucketObjectURL(uri)) + "?" + queryString;

    /**
     * @returns {Promise<[boolean]>}
     */
    const executeCall = async () => {
      const headers = await this._getAWSAuthorizationHeader(
        "POST",
        uri,
        queryString,
        {
          // JS has no access to system btime.
          // [this.btimeHeaderName]: file.lastModified.toString(),
          [this.mtimeHeaderName]: file.lastModified.toString(),
          ...extraHeaders,
        },
      );

      const abortController = new AbortController();
      const uploadInfo = {
        abortController,
        cancelled: false,
      };
      this.#fileUploadDataMap.set(file, uploadInfo);

      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "POST",
        body: new File([], file.name, {
          type: file.type,
        }),
        signal: abortController.signal,
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        if (uploadInfo.cancelled) {
          return [false];
        }
        throw new RetryError();
      }

      const xmlDoc = this.#parseXMLDocument(await response.text());
      if (!xmlDoc) {
        throw new RetryError();
      }
      const uploadId = xmlDoc.querySelector("UploadId")?.textContent;
      if (!uploadId) {
        if (uploadInfo.cancelled) {
          return [false];
        }
        throw new RetryError();
      }

      this.#fileUploadDataMap.set(file, {
        uploadId,
      });

      if (uploadInfo.cancelled) {
        await this.abortMultipartUpload(uri, file);
        return [false];
      }

      return /** @type {[boolean]} */ ([true]);
    };
    return this.#retryCalls(executeCall);
  }

  /**
   * @override
   * @param {string} uri
   * @param {File} file
   * @param {number} partNumber
   * @param {{[key: string]: string}} [extraHeaders]
   * @return {Promise<{url: string, headers: {[key: string]: string}, method: "PUT"|"POST"}>}
   */
  async getUploadPartAuthorizationInfo(
    uri,
    file,
    partNumber,
    extraHeaders = {},
  ) {
    const uploadInfo = this.#fileUploadDataMap.get(file);
    if (!uploadInfo?.uploadId) {
      throw new Error();
    }

    const queryString =
      "partNumber=" +
      partNumber.toString() +
      "&uploadId=" +
      uriEncode(uploadInfo.uploadId);

    return {
      url: (await this._getAWSBucketObjectURL(uri)) + "?" + queryString,
      headers: await this._getAWSAuthorizationHeader(
        "PUT",
        uri,
        queryString,
        extraHeaders,
        "UNSIGNED-PAYLOAD",
      ),
      method: "PUT",
    };
  }

  /**
   * @override
   * @param {string} uri
   * @param {File} file
   * @param {number} totalPartCount
   * @param {({eTag: string})[]} partsData
   * @param {{[key: string]: string}} [extraHeaders]
   */
  async completeMultipartUpload(
    uri,
    file,
    totalPartCount,
    partsData,
    extraHeaders = {},
  ) {
    const uploadInfo = this.#fileUploadDataMap.get(file);
    if (!uploadInfo?.uploadId) {
      throw new Error();
    }

    const queryString = "uploadId=" + uriEncode(uploadInfo.uploadId);
    const url = (await this._getAWSBucketObjectURL(uri)) + "?" + queryString;

    /**
     * @returns {Promise<[boolean]>}
     */
    const executeCall = async () => {
      const headers = await this._getAWSAuthorizationHeader(
        "POST",
        uri,
        queryString,
        extraHeaders,
        "UNSIGNED-PAYLOAD",
      );

      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "POST",
        body: `<?xml version="1.0" encoding="UTF-8"?><CompleteMultipartUpload xmlns="http://s3.amazonaws.com/doc/2006-03-01/">${partsData
          .map(
            ({ eTag }, i) =>
              `<Part><PartNumber>${i + 1}</PartNumber><ETag>${eTag}</ETag></Part>`,
          )
          .join("")}</CompleteMultipartUpload>`,
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      this.#parseXMLDocument(await response.text());
      this.#fileUploadDataMap.delete(file);
      return /** @type {[boolean]} */ ([true]);
    };

    return this.#retryCalls(executeCall);
  }

  /**
   * @override
   * @param {string} uri
   * @param {File} file
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async abortMultipartUpload(uri, file) {
    const uploadInfo = this.#fileUploadDataMap.get(file);
    if (!uploadInfo) {
      throw new Error();
    }

    if (uploadInfo.abortController) {
      uploadInfo.abortController.abort();
      uploadInfo.cancelled = true;
      this.#fileUploadDataMap.delete(file);
      return [true];
    }

    if (!uploadInfo.uploadId) {
      throw new Error();
    }

    const queryString = "uploadId=" + uriEncode(uploadInfo.uploadId);
    const url = (await this._getAWSBucketObjectURL(uri)) + "?" + queryString;

    const executeCall = async () => {
      const headers = await this._getAWSAuthorizationHeader(
        "DELETE",
        uri,
        queryString,
        {},
        "UNSIGNED-PAYLOAD",
      );

      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "DELETE",
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      this.#parseXMLDocument(await response.text());
      this.#fileUploadDataMap.delete(file);
      return /** @type {[boolean]} */ ([true]);
    };
    return this.#retryCalls(executeCall);
  }

  /**
   * @override
   * @param {XMLHttpRequest} xhr
   */
  handleUploadComplete(xhr) {
    this._updateAWSTimeOffset(xhr.getResponseHeader("Date") ?? undefined);

    if (xhr.responseText.length !== 0) {
      const xmlDoc = xhr.responseXML;
      if (xmlDoc) {
        const errorInfo = this.#getAWSErrorInfo(xmlDoc);
        if (errorInfo) {
          return {
            success: false,
            errorInfo: errorInfo,
          };
        }
      }
    }

    if (xhr.status !== 200) {
      return {
        success: false,
      };
    }

    return {
      success: true,
    };
  }

  /**
   * @override
   * @param {XMLHttpRequest} xhr
   */
  handleUploadPartComplete(xhr) {
    this._updateAWSTimeOffset(xhr.getResponseHeader("Date") ?? undefined);

    if (xhr.responseText.length !== 0) {
      const xmlDoc = xhr.responseXML;
      if (xmlDoc) {
        const errorInfo = this.#getAWSErrorInfo(xmlDoc);
        if (errorInfo) {
          return {
            success: /** @type {const} */ (false),
            errorInfo: errorInfo,
          };
        }
      }
    }

    if (xhr.status !== 200) {
      return {
        success: /** @type {const} */ (false),
      };
    }

    const data = {
      eTag: xhr.getResponseHeader("ETag") ?? undefined,
    };
    return {
      success: true,
      data,
    };
  }

  /**
   * @override
   * @param {string} [listPrefix]
   * @return {Promise<[false|(FolderInfo|FileInfo)[], ErrorInfo?]>}
   */
  async listFiles(listPrefix = "") {
    /** @type {[string, string][]} */
    const searchParams = [];
    searchParams.push(["delimiter", "/"]);
    if (listPrefix) {
      searchParams.push(["prefix", await this.encryptName(listPrefix)]);
    }
    searchParams.sort(([a], [b]) => (a === b ? 0 : a < b ? -1 : 1));
    const queryString = searchParams
      .map(([key, value]) => `${uriEncode(key)}=${uriEncode(value)}`)
      .join("&");
    const url = (await this._getAWSBucketObjectURL()) + "?" + queryString;

    /**
     * @return {Promise<[false|(FolderInfo|FileInfo)[], ErrorInfo?]>}
     */
    const executeCall = async () => {
      const headers = await this._getAWSAuthorizationHeader(
        "GET",
        "/",
        queryString,
      );

      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "GET",
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      const xmlDoc = this.#parseXMLDocument(await response.text());
      if (!xmlDoc) {
        throw new RetryError();
      }

      // Example response can be found at
      // http://docs.aws.amazon.com/AmazonS3/latest/API/RESTBucketGET.html
      const prefixNodeList = /** @type {Element} */ (
        xmlDoc.firstChild
      ).querySelectorAll("CommonPrefixes > Prefix");
      const prefixes = /** @type {string[]} */ (
        Array.prototype.map.call(prefixNodeList, function (prefix) {
          return prefix.textContent;
        })
      );

      const keyNodeList = /** @type {Element} */ (
        xmlDoc.firstChild
      ).getElementsByTagName("Key");
      const keys = /** @type {string[]} */ (
        Array.prototype.map.call(keyNodeList, function (key) {
          return key.textContent;
        })
      );
      const lastModifiedNodeList = /** @type {Element} */ (
        xmlDoc.firstChild
      ).getElementsByTagName("LastModified");
      const lastModifiedDates = /** @type {Date[]} */ (
        Array.prototype.map.call(lastModifiedNodeList, function (lastModified) {
          return new Date(lastModified.textContent);
        })
      );
      const sizeNodeList = /** @type {Element} */ (
        xmlDoc.firstChild
      ).getElementsByTagName("Size");

      const sizes = /** @type {number[]} */ (
        Array.prototype.map.call(sizeNodeList, function (size) {
          return parseInt(size.textContent);
        })
      );

      const [folderInfoArr, fileInfoArr] = await Promise.all([
        Promise.all(
          prefixes.map(async (prefix) => {
            const decryptedPrefix = await this.decryptName(prefix);
            if (decryptedPrefix === null) {
              return null;
            }
            return {
              is: /** @type {const} */ ("folder"),
              key: decryptedPrefix,
              folderName: decryptedPrefix
                .substring(listPrefix.length)
                .replace(/\/$/, ""),
            };
          }),
        ),
        Promise.all(
          keys.map(async (key, i) => {
            const decryptedKey = await this.decryptName(key);
            if (decryptedKey === null) {
              return null;
            }
            return {
              is: /** @type {const} */ ("file"),
              key: decryptedKey,
              filename: decryptedKey.substring(listPrefix.length),
              uploaded: lastModifiedDates[i],
              size: await this.decryptSize(sizes[i]),
              storedSize: sizes[i],
            };
          }),
        ),
      ]);

      const validFolderInfoArr = folderInfoArr.filter(
        (folderInfo) => folderInfo !== null,
      );
      const validFileInfoArr = fileInfoArr.filter(
        (fileInfo) => fileInfo !== null,
      );

      validFolderInfoArr.sort((a, b) => (a.key < b.key ? -1 : 1));
      validFileInfoArr.sort((a, b) => (a.key < b.key ? -1 : 1));

      return [[...validFolderInfoArr, ...validFileInfoArr]];
    };

    return this.#retryCalls(executeCall);
  }

  /**
   * @override
   * @param {string} uri
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async deleteFile(uri) {
    const url = await this._getAWSBucketObjectURL(uri);

    const executeCall = async () => {
      const headers = await this._getAWSAuthorizationHeader("DELETE", uri);
      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "DELETE",
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      this.#parseXMLDocument(await response.text());
      return /** @type {[boolean]} */ ([response.status === 204]);
    };
    return this.#retryCalls(executeCall);
  }

  /** @override */
  btimeHeaderName = "x-amz-meta-creation-time";
  /** @override */
  mtimeHeaderName = "x-amz-meta-mtime";

  /**
   * @override
   * @param {string} uri
   * @return {Promise<[false|Stat, ErrorInfo?]>}
   */
  async statFile(uri) {
    const url = await this._getAWSBucketObjectURL(uri);

    /**
     * @return {Promise<[false|Stat, ErrorInfo?]>}
     */
    const executeCall = async () => {
      const headers = await this._getAWSAuthorizationHeader("HEAD", uri);
      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "HEAD",
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      const contentLengthString = response.headers.get("Content-Length");

      return [
        {
          contentType: response.headers.get("Content-Type") ?? undefined,
          size: contentLengthString
            ? ((await this.decryptSize(parseInt(contentLengthString))) ??
              undefined)
            : undefined,
          storedSize: contentLengthString
            ? parseInt(contentLengthString)
            : undefined,
          eTag: response.headers.get("ETag") ?? undefined,
          uploaded: parseDate(response.headers.get("Last-Modified")),
          modified: parseDate(
            parseInt(response.headers.get(this.mtimeHeaderName) ?? ""),
          ),
          created: parseDate(
            parseInt(response.headers.get(this.btimeHeaderName) ?? ""),
          ),
        },
      ];
    };

    return this.#retryCalls(executeCall);
  }

  /**
   * @override
   * @param {string} uri
   * @param {string} newUri
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async copyFile(uri, newUri) {
    if (!this.config) {
      return [false];
    }
    const [stat] = await this.statFile(uri);
    if (!stat || !stat.size) {
      return [false];
    }

    const { filename } = splitKey(keyFromUri(uri));
    const file = new File([new Uint8Array(stat.size)], filename, {
      type: stat.contentType,
    });

    const extraHeaders = {
      "x-amz-copy-source": `/${this.config.bucketName}${uriEncode(await this.encryptName(uri), true)}`,
    };

    if (file.size <= AmazonS3FileStore.copyPartSize) {
      /**
       * @returns {Promise<[boolean, ErrorInfo?]>}
       */
      const executeCall = async () => {
        const { url, headers, method } = await this.getUploadAuthorizationInfo(
          newUri,
          file,
          extraHeaders,
        );
        const response = await fetch(url, {
          method,
          headers,
          body: new File([], filename, {
            type: stat.contentType,
          }),
        });

        if (response.status >= 300) {
          return [false];
        }

        this.#parseXMLDocument(await response.text());
        return [true];
      };
      return this.#retryCalls(executeCall);
    }

    {
      const [result, errorInfo] = await this.createMultipartUpload(
        newUri,
        file,
        {},
      );
      if (!result) {
        return [false, errorInfo];
      }
    }

    let startByte = 0;
    let index = 0;
    /** @type {{eTag: string}[]} */
    const partsData = [];

    while (startByte < file.size) {
      const endByte =
        startByte + AmazonS3FileStore.copyPartSize > file.size
          ? file.size - 1
          : startByte + AmazonS3FileStore.copyPartSize;
      const { url, headers, method } =
        await this.getUploadPartAuthorizationInfo(newUri, file, index + 1, {
          "x-amz-copy-source-range": `bytes=${startByte}-${endByte}`,
          ...extraHeaders,
        });

      /**
       * @returns {Promise<[true]>}
       */
      const executeCall = async () => {
        const response = await fetch(url, {
          method,
          headers,
        });

        const xmlDoc = this.#parseXMLDocument(await response.text());
        if (!xmlDoc) {
          throw new RetryError();
        }
        const eTag = xmlDoc.querySelector("ETag")?.textContent;
        if (!eTag) {
          throw new Error();
        }

        partsData.push({
          eTag,
        });

        return [true];
      };
      const [result, errorInfo] = await this.#retryCalls(executeCall);
      if (!result) {
        return [result, errorInfo];
      }

      startByte = endByte + 1;
      index++;
    }

    {
      const [result, errorInfo] = await this.completeMultipartUpload(
        newUri,
        file,
        partsData.length,
        partsData,
        extraHeaders,
      );
      if (!result) {
        return [result, errorInfo];
      }
      return [true];
    }
  }

  /**
   * @override
   * @param {string} uri
   * @param {string} newUri
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async moveFile(uri, newUri) {
    const [result, errorInfo] = await this.copyFile(uri, newUri);
    if (!result) {
      return [result, errorInfo];
    }
    return this.deleteFile(uri);
  }

  /**
   * @param {string} [dateString]
   */
  _updateAWSTimeOffset(dateString) {
    if (!dateString) return;

    const currentTime = new Date().getTime();
    const awsTime = new Date(dateString).getTime();

    const offset = awsTime - currentTime;
    if (offset > 1000) this.awsTimeOffset = offset;
  }

  _getAWSDate() {
    if (this.awsTimeOffset === 0) {
      return new Date();
    }
    return new Date(new Date().getTime() + this.awsTimeOffset);
  }

  /**
   * @param {"GET"|"HEAD"|"POST"|"PUT"|"DELETE"} method
   * @param {string} [uri]
   * @param {string} [queryString]
   * @param {{[key: string]: string}} [extraHeaders]
   * @param {string} [contentSha256]
   */
  async _getAWSAuthorizationHeader(
    method,
    uri = "/",
    queryString = "",
    extraHeaders = {},
    contentSha256 = "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855",
  ) {
    if (!this.config) {
      throw new Error();
    }

    const { region, accessKeyId } = this.config;
    const date = this._getAWSDate();
    const dateString = `${date.getUTCFullYear()}${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}${date.getUTCDate().toString().padStart(2, "0")}`;
    const dateTimeString = `${dateString}T${date
      .getUTCHours()
      .toString()
      .padStart(2, "0")}${date
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}${date.getUTCSeconds().toString().padStart(2, "0")}Z`;

    const host = new URL(await this._getAWSBucketObjectURL()).host;

    const signedHeaders = ["host", "x-amz-content-sha256", "x-amz-date"]
      .concat(Object.keys(extraHeaders))
      .sort();

    let canonicalHeaders = "";
    for (const header of signedHeaders) {
      switch (header) {
        case "host":
          canonicalHeaders += `host:${host}\n`;
          break;
        case "x-amz-content-sha256":
          canonicalHeaders += `x-amz-content-sha256:${contentSha256}\n`;
          break;
        case "x-amz-date":
          canonicalHeaders += `x-amz-date:${dateTimeString}\n`;
          break;
        default:
          canonicalHeaders += `${header}:${extraHeaders[header]}\n`;
          break;
      }
    }

    const canonicalRequestString =
      /* HTTPMethod */ method +
      "\n" +
      /* CanonicalURI */ `${uriEncode(await this.encryptName(uri), true)}\n` +
      /* CanonicalQueryString */ queryString +
      "\n" +
      /* CanonicalHeaders */ `${canonicalHeaders}\n` +
      /* SignedHeaders */ `${signedHeaders.join(";")}\n` +
      /* HashedPayload */ contentSha256;

    const textEncoder = new TextEncoder();

    const stringToSign =
      "AWS4-HMAC-SHA256" +
      "\n" +
      dateTimeString +
      "\n" +
      `${dateString}/${region}/s3/aws4_request` +
      "\n" +
      bufferToHex(
        await crypto.subtle.digest(
          "SHA-256",
          textEncoder.encode(canonicalRequestString),
        ),
      );

    const signingKey = await hmacSha256Sign(
      textEncoder.encode("aws4_request"),
      await hmacSha256Sign(
        textEncoder.encode("s3"),
        await hmacSha256Sign(
          textEncoder.encode(region),
          await hmacSha256Sign(
            textEncoder.encode(dateString),
            textEncoder.encode("AWS4" + this.config.secretAccessKey),
          ),
        ),
      ),
    );

    return {
      Authorization:
        "AWS4-HMAC-SHA256 " +
        `Credential=${accessKeyId}/${dateString}/${region}/s3/aws4_request,` +
        `SignedHeaders=${signedHeaders.join(";")},` +
        `Signature=${bufferToHex(
          await hmacSha256Sign(textEncoder.encode(stringToSign), signingKey),
        )}`,
      "x-amz-content-sha256": contentSha256,
      "x-amz-date": dateTimeString,
      ...extraHeaders,
    };
  }

  /**
   * @param {string} [uri]
   * @param {string} [protocol]
   * @returns
   */
  async _getAWSBucketObjectURL(uri = "/", protocol) {
    const config = this.config;
    if (!config) {
      throw new Error();
    }

    const url = this.AWS_BUCKET_URL.replace("%protocol", protocol || "https")
      .replace("%bucketName", config.bucketName)
      .replace("%uri", uriEncode(await this.encryptName(uri), true));

    return url;
  }

  /**
   * @param {string} responseText
   * @throws {RetryError}
   * @returns {XMLDocument|null}
   */
  #parseXMLDocument(responseText) {
    const parser = new DOMParser();
    if (responseText === "") {
      return null;
    }

    const xmlDoc = /** @type {XMLDocument} */ (
      parser.parseFromString(responseText, "text/xml")
    );
    if (!xmlDoc) {
      throw new RetryError();
    }

    const errorInfo = this.#getAWSErrorInfo(xmlDoc);
    if (errorInfo) {
      throw new RetryError(errorInfo);
    }

    return xmlDoc;
  }

  /**
   * @param {XMLDocument} xmlDoc
   * @returns {ErrorInfo|null}
   */
  #getAWSErrorInfo(xmlDoc) {
    const xmlRoot = /** @type {Element} */ (xmlDoc.firstChild);
    if (xmlRoot.nodeName !== "Error") {
      return null;
    }
    // Example response can be found at
    // http://docs.aws.amazon.com/AmazonS3/latest/API/ErrorResponses.html#RESTErrorResponses
    return {
      code: xmlRoot.getElementsByTagName("Code")[0].textContent ?? "",
      message: xmlRoot.getElementsByTagName("Message")[0].textContent ?? "",
    };
  }
}

class BackBlazeB2FileStore extends AmazonS3FileStore {
  /** @override */
  AWS_BUCKET_URL = "https://%bucketName.s3.%region.backblazeb2.com%uri";

  /** @override */
  btimeHeaderName = "x-amz-meta-src_creation_date_millis";
  /** @override */
  mtimeHeaderName = "x-amz-meta-src_last_modified_millis";

  /**
   * @override
   * @param {string} uri
   */
  async _getAWSBucketObjectURL(uri = "/") {
    if (!this.config) {
      throw new Error();
    }
    const { region, bucketName } = this.config;

    return this.AWS_BUCKET_URL.replace("%region", region)
      .replace("%bucketName", bucketName)
      .replace("%uri", uriEncode(await this.encryptName(uri), true));
  }
}

export { FileStoreBase, AmazonS3FileStore, BackBlazeB2FileStore };
