import Localizer from "./localizer.js";

/**
 * @param {number} size
 * @param {boolean} [tabular]
 */
export default function getSizeString(size, tabular = false) {
  const localizer = Localizer.sharedLocalizer;

  const minimumFractionDigits = tabular ? 1 : undefined;
  const maximumFractionDigits = tabular ? 1 : 2;

  const locale = localizer?.locale ?? navigator.language;

  if (size < 1e3) {
    const formatter = Intl.NumberFormat(locale, {
      style: "unit",
      maximumFractionDigits,
      minimumFractionDigits,
      unit: "byte",
    });
    return formatter.format(size);
  }
  if (size < 1e6) {
    const formatter = Intl.NumberFormat(locale, {
      style: "unit",
      maximumFractionDigits,
      minimumFractionDigits,
      unit: "kilobyte",
    });
    return formatter.format(size / 1e3);
  }
  if (size < 1e9) {
    const formatter = Intl.NumberFormat(locale, {
      style: "unit",
      maximumFractionDigits,
      minimumFractionDigits,
      unit: "megabyte",
    });
    return formatter.format(size / 1e6);
  }
  const formatter = Intl.NumberFormat(locale, {
    style: "unit",
    maximumFractionDigits,
    minimumFractionDigits,
    unit: "gigabyte",
  });
  return formatter.format(size / 1e9);
}
