import EncryptionController from "./encryption-controller.js";

/**
 * @template {any} T
 * @param {T} obj
 */
const NonNullable = (obj) => /** @type {NonNullable<T>} */ (obj);

class DropAPI {
  /**
   * @param {typeof import("./config-store.js").ConfigStoreBase} ConfigStore
   * @param {typeof import("./file-store.js").FileStoreBase} FileStore
   */
  constructor(ConfigStore, FileStore) {
    this.fileStore = new FileStore();
    this.passthroughFileStore = new FileStore();
    this.configStore = new ConfigStore();
  }

  /** @type {((hasAccess: true) => void)|null} */
  onConfigReady = null;
  /** @type {((hasAccess: false, errorInfo?: { code: string|null, message: string|null }) => void)|null} */
  onConfigError = null;

  getConfig() {
    if (!this.configStore) {
      throw new Error();
    }

    this.configStore.onConfigReady = this.configStore.onConfigError =
      /**
       * @param {boolean} hasAccess
       * @param {{code: string|null, message: string|null}} [errorInfo]
       */
      (hasAccess, errorInfo) =>
        this.handleConfigStoreChange(hasAccess, errorInfo);

    this.configStore.getConfig();
  }

  clearConfig() {
    this.configStore.clearConfig();
    this.fileStore.config = null;
    this.passthroughFileStore.config = null;

    this.#encryptionController?.clearConfig();
    this.#encryptionController = null;
  }

  /** @type {EncryptionController|null} */
  #encryptionController = null;

  /**
   * @param {boolean} hasAccess
   * @param {{code: string|null, message: string|null}} [errorInfo]
   */
  handleConfigStoreChange(hasAccess, errorInfo) {
    if (!this.fileStore || !this.configStore) {
      throw new Error();
    }

    if (hasAccess) {
      this.fileStore.config = this.configStore.config;
      this.passthroughFileStore.config = this.configStore.config;

      if (
        this.configStore.config &&
        this.configStore.config.cryptPassword &&
        this.configStore.config.cryptSalt
      ) {
        this.setEnabledEncryption(true);
      }

      if (this.onConfigReady) {
        this.onConfigReady(true);
      }
    } else {
      if (this.onConfigError) {
        this.onConfigError(false, errorInfo);
      }
    }
  }

  get encryptionAvailable() {
    return (
      !!this.configStore.config?.cryptPassword &&
      !!this.configStore.config?.cryptSalt
    );
  }

  /**
   * @param {boolean} enabled
   * @returns
   */
  setEnabledEncryption(enabled) {
    if (!this.encryptionAvailable) {
      return;
    }
    if (enabled) {
      const config = NonNullable(this.configStore.config);
      const encryptionController = new EncryptionController();
      this.#encryptionController = encryptionController;
      encryptionController.setConfig(
        NonNullable(config.cryptPassword),
        NonNullable(config.cryptSalt),
      );
      this.fileStore.onNameDecryption = (name) =>
        encryptionController.decryptName(name);
      this.fileStore.onNameEncryption = (name) =>
        encryptionController.encryptName(name);
      this.fileStore.onSizeDecryption = (size) =>
        encryptionController.decryptedFileSize(size);
      this.fileStore.onSizeEncryption = (size) =>
        encryptionController.encryptedFileSize(size);
    } else {
      this.#encryptionController = null;
      this.fileStore.onNameDecryption = null;
      this.fileStore.onNameEncryption = null;
      this.fileStore.onSizeDecryption = null;
      this.fileStore.onSizeEncryption = null;
    }
  }

  get fileEncryption() {
    return !!this.#encryptionController;
  }

  /**
   * @param {File} file
   */
  async encryptFile(file) {
    return await NonNullable(this.#encryptionController).encryptFile(file);
  }

  /**
   * @param {ReadableStream<Uint8Array>} stream
   */
  async decryptFile(stream) {
    return await NonNullable(this.#encryptionController).decryptFile(stream);
  }

  /**
   * @param {string} name
   */
  async encryptName(name) {
    return await NonNullable(this.#encryptionController).encryptName(name);
  }

  /**
   * @param {string} name
   */
  async decryptName(name) {
    return await NonNullable(this.#encryptionController).decryptName(name);
  }

  /**
   * @param {number} size
   */
  async encryptedFileSize(size) {
    return NonNullable(this.#encryptionController).encryptedFileSize(size);
  }

  /**
   * @param {number} size
   */
  async decryptedFileSize(size) {
    return NonNullable(this.#encryptionController).decryptedFileSize(size);
  }
}

export default DropAPI;
