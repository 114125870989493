import StreamSlicer from "./stream-slicer.js";

/**
 * Split a file or stream into parts.
 * Each part is form as a Blob object with bytes copied from the source.
 */
export class FilePartReader {
  /**
   * @param {File|ReadableStream} source
   * @param {number} partSize
   */
  constructor(source, partSize) {
    this.#source = source;
    this.#partSize = partSize;
  }

  partSizeMultiple = 1;

  #source;
  #partSize;
  #partCount = 0;
  /** @type {ReadableStreamDefaultReader<Uint8Array>?} */
  #reader = null;

  start() {
    const stream =
      this.#source instanceof ReadableStream
        ? this.#source
        : this.#source.stream();

    this.#reader = stream
      .pipeThrough(
        new TransformStream(
          /** @type {Transformer<Uint8Array, Uint8Array>} */ (
            new StreamSlicer(this.#partSize)
          ),
        ),
      )
      .getReader();

    this.startNextFilePart();
  }

  /** @type {Uint8Array[]} */
  #currentParts = [];
  /** @type {Uint8Array[]} */
  #nextParts = [];

  #fileEnded = false;

  async restartFilePart() {
    this.#nextParts = [...this.#currentParts, ...this.#nextParts];
    this.#partCount--;
    this.startNextFilePart();
  }

  async startNextFilePart() {
    if (this.#fileEnded && this.#nextParts.length === 0) {
      this.onFileEnd?.(this.#partCount);
      return;
    }

    if (!this.#reader) {
      throw new Error();
    }

    if (this.#nextParts.length) {
      const parts = this.#nextParts.splice(0, this.partSizeMultiple);
      this.#currentParts = parts;
    } else {
      this.#currentParts = [];
    }

    while (
      !this.#fileEnded &&
      this.#currentParts.length !== this.partSizeMultiple
    ) {
      const result = await this.#reader.read();
      const part = result.value;
      if (!part) {
        if (this.#currentParts.length === 0) {
          this.onFileEnd?.(this.#partCount);
          return;
        } else {
          this.#fileEnded = true;
        }
        break;
      }
      this.#currentParts.push(part);
    }

    this.onFilePart?.(new Blob(this.#currentParts), this.#partCount);
    this.#partCount++;
  }

  /**
   * When a file part is available
   * @type {((blob: Blob, index: number) => void)|null}
   **/
  onFilePart = null;

  /**
   * When a file ends
   * @type {((totalPartCount: number) => void)|null}
   **/
  onFileEnd = null;
}
